import { UserPreferences } from '../models/user';
import apiClient from './apiClient';

const prefix = '/preference';

const preferences = {
  set: async (data: UserPreferences): Promise<string> => await apiClient.post(`${prefix}`, data),
  get: async (): Promise<UserPreferences> => await apiClient.get(`${prefix}`),
};

export default preferences;
