import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { UserType } from '../models/user';
import paths from '../routing/paths';
import useUser from '../state/useUser';
import AdminNav from '../components/smart/AdminNav';

const ProtectedScreens = ({ admin }: { admin?: boolean }) => {
  const [user] = useUser();
  const location = useLocation();

  if (!user.id) {
    const redirect =
      location.pathname === paths.profile
        ? ''
        : `?returnUrl=${location.pathname}${location.search ? `?${location.search}` : ''}`;
    return <Navigate to={`${paths.login}${redirect}`} replace />;
  }

  if (admin && user.userType !== UserType.Admin) {
    return <>Reserved for system administrators</>;
  }

  if (admin) {
    return (
      <AdminNav>
        <Outlet />
      </AdminNav>
    );
  }

  return <Outlet />;
};

export default ProtectedScreens;
