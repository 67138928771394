import { Navigate, Outlet, useLocation } from 'react-router-dom';
import NavBar from '../components/smart/NavBar';
import paths from './paths';

const Root = () => {
  const location = useLocation();
  if (location.pathname === paths.home) {
    return <Navigate to={paths.discover} replace />;
  }
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
};

export default Root;
