import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import paths from '../../../routing/paths';
import useUser from '../../../state/useUser';

const withUnauthenticatedOnlyGuard = (WrappedComponent: React.ComponentType) => {
  return () => {
    const [user] = useUser();
    const [loggedInOnInit] = useState(Boolean(user.id));

    if (loggedInOnInit) {
      return <Navigate to={paths.home} />;
    }

    return <WrappedComponent />;
  };
};

export default withUnauthenticatedOnlyGuard;
