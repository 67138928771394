import {
  useTheme,
  Box,
  Card,
  Typography,
  Icon,
  Theme,
  Button,
  CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import api from '../api';
import CardScreen from '../components/CardScreen';
import paths from '../routing/paths';
import { Restaurant, Sentiment } from '../models/restaurant';
import React from 'react';
import AppButton from '../components/AppButton';

const tabTitles = {
  [Sentiment.Unknown]: '',
  [Sentiment.KeenToGo]: 'Keen to try',
  [Sentiment.NotInterested]: 'Not interested',
};

interface TabItem {
  value: Sentiment;
  title: string;
  iconText: string;
  iconColor: string;
}

const buildTabs = (theme: Theme): TabItem[] => {
  return [
    {
      value: Sentiment.KeenToGo,
      title: tabTitles[Sentiment.KeenToGo],
      iconText: 'favorite',
      iconColor: theme.palette.success.main,
    },
    {
      value: Sentiment.NotInterested,
      title: tabTitles[Sentiment.NotInterested],
      iconText: 'close',
      iconColor: theme.palette.error.main,
    },
  ];
};

const UserLists = () => {
  const location = useLocation();
  const [tab] = useState<Sentiment>(
    (new URLSearchParams(location.search).get('type') as Sentiment) || Sentiment.KeenToGo,
  );
  const theme = useTheme();
  const tabs = buildTabs(theme);
  const navigate = useNavigate();
  const [keenList, setKeenList] = useState<Restaurant[]>([]);
  const [uninterestedList, setUninterestedList] = useState<Restaurant[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (tab === Sentiment.KeenToGo) {
      Promise.all([api.restaurant.getBySentiment(Sentiment.KeenToGo)]).then(([keen]) => {
        setKeenList(keen);
        setIsLoading(false);
      });
    }
    if (tab === Sentiment.NotInterested) {
      Promise.all([api.restaurant.getBySentiment(Sentiment.NotInterested)]).then(([response]) => {
        setUninterestedList(response);
        setIsLoading(false);
      });
    }
  }, [tab]);

  const handleRemoveClick = async (restaurant: Restaurant) => {
    const result = await api.sentiment.delete(restaurant.id as string, tab);

    if (!result) {
      return;
    }

    if (tab === Sentiment.KeenToGo) {
      setKeenList((prev) => prev.filter((item) => item.id !== restaurant.id));
    }

    if (tab === Sentiment.NotInterested) {
      setUninterestedList((prev) => prev.filter((item) => item.id !== restaurant.id));
    }
  };

  const buildRestaurantCard = (restaurant: Restaurant) => {
    return (
      <Card sx={{ px: 2, py: 1, mb: 1 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <>{restaurant.title}</>
          <Button onClick={() => handleRemoveClick(restaurant)}>
            <Typography
              sx={{
                borderBottom: `1px solid ${theme.palette.common.black}`,
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Remove
            </Typography>
          </Button>
        </Box>
      </Card>
    );
  };

  const emptyState = (
    <>
      <Typography mb={2} variant="body2">
        No items exist here yet
      </Typography>
      <Link to={paths.discover}>
        <AppButton>Discover</AppButton>
      </Link>
    </>
  );

  return (
    <CardScreen showBack onBackClick={() => navigate(-1)}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h6" mr={1}>
          {tabs.find((item) => item.value === tab)?.title}
        </Typography>
        <Icon sx={{ color: tabs.find((item) => item.value === tab)?.iconColor, fontSize: 32 }}>
          {tabs.find((item) => item.value === tab)?.iconText}
        </Icon>
      </Box>
      {isLoading && (
        <Box mt={3}>
          <CircularProgress thickness={5} size={28} />
        </Box>
      )}
      {tab === Sentiment.KeenToGo && (
        <Box mt={2}>
          {!keenList.length && !isLoading && <>{emptyState}</>}
          {keenList.map((item) => (
            <React.Fragment key={item.id}>{buildRestaurantCard(item)}</React.Fragment>
          ))}
        </Box>
      )}
      {tab === Sentiment.NotInterested && (
        <Box mt={2}>
          {!uninterestedList.length && <>{emptyState}</>}
          {uninterestedList.map((item) => (
            <React.Fragment key={item.id}>{buildRestaurantCard(item)}</React.Fragment>
          ))}
        </Box>
      )}
    </CardScreen>
  );
};

export default UserLists;
