import { Sentiment } from '../models/restaurant';
import { SentimentOverlap } from '../models/sentiment';
import apiClient from './apiClient';

const prefix = '/sentiment';

const sentiment = {
  create: async (data: { restaurantId: string; sentiment: Sentiment }): Promise<string> =>
    await apiClient.post(`${prefix}`, data),
  delete: async (restaurantId: string, sentiment: Sentiment): Promise<boolean> =>
    await apiClient.delete(`${prefix}/${restaurantId}/${sentiment}`),
  findOverlap: async (userIds: string[]): Promise<SentimentOverlap[]> =>
    await apiClient.post(`${prefix}/overlap/keen`, { userIds }),
};

export default sentiment;
