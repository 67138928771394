import { Box, Card, Icon, IconButton, Typography, useTheme } from '@mui/material';
import useScreenWidth from '../hooks/useScreenWidth';
import { ReactComponent as Logo } from '../assets/logo.svg';

interface CardScreenProps {
  children: React.ReactNode;
  title?: string;
  showBack?: boolean;
  showLogo?: boolean;
  onBackClick?(): void;
}

const CardScreen = ({ children, title, showBack, showLogo, onBackClick }: CardScreenProps) => {
  const theme = useTheme();
  const screenWidth = useScreenWidth();
  return (
    <Box
      display="flex"
      position="relative"
      justifyContent="center"
      sx={{
        mt: 8,
      }}
    >
      <Card
        sx={{
          margin: 'auto',
          padding: 3,
          width: '100%',
          textAlign: 'center',
          position: 'relative',
          borderRadius: 0,
          boxShadow: 'none',
          [theme.breakpoints.up('sm')]: {
            borderRadius: 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            maxWidth: 480,
          },
        }}
      >
        {showBack && (
          <Box sx={{ position: 'absolute', left: 24, top: 24, zIndex: 2 }}>
            <IconButton onClick={onBackClick} color="primary">
              <Icon>arrow_back</Icon>
            </IconButton>
          </Box>
        )}
        {showLogo && (
          <Logo style={{ height: screenWidth < theme.breakpoints.values.sm ? 120 : 128 }} />
        )}
        {title && (
          <Typography
            sx={{ marginBottom: 2, zIndex: 1, position: 'relative' }}
            fontWeight={700}
            variant={screenWidth < theme.breakpoints.values.sm ? 'h5' : 'h5'}
          >
            {title}
          </Typography>
        )}
        {children}
      </Card>
    </Box>
  );
};

export default CardScreen;
