import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography, Box } from '@mui/material';

import api from '../api';
import CardScreen from '../components/CardScreen';
import useNotification from '../state/useNotification';
import AppButton from '../components/AppButton';
import paths from '../routing/paths';
import useUser from '../state/useUser';

const ConfirmEmail = () => {
  const [, setUser] = useUser();
  const [isSaving, setIsSaving] = useState(true);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [, setNotification] = useNotification();
  const search = useLocation().search;
  const navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(search).get('token');
    if (!token) {
      setIsSaving(false);
      return;
    }

    api.auth
      .confirmEmail({
        token,
      })
      .then(async () => {
        setIsSaving(false);
        setIsSuccess(true);
        setNotification({ message: 'Email successfully verified' });
      })
      .catch(() => {
        setIsSaving(false);
      });
  }, []);

  const handleContinueClick = async () => {
    if (isSuccess) {
      setIsFetchingUser(true);
      const userResponse = await api.user.getMe();
      setUser(userResponse);
      setIsFetchingUser(false);
    }
    navigate(paths.home);
  };

  if (isSaving) {
    return (
      <CardScreen title="Confirming email">
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="primary" thickness={4} />
        </Box>
      </CardScreen>
    );
  }

  return (
    <CardScreen title={isSuccess ? 'Email verified!' : 'Invalid Link'}>
      <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
        {isSuccess
          ? 'Your email has been successfully verified!'
          : 'We were unable to verify your email. Please try again'}
      </Typography>
      <AppButton isLoading={isFetchingUser} onClick={handleContinueClick}>
        Continue
      </AppButton>
    </CardScreen>
  );
};

export default ConfirmEmail;
