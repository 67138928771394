import { useNavigate } from 'react-router-dom';
import CardScreen from '../components/CardScreen';
import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  MenuItem,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PricePoints from '../components/PricePoints';
import AppButton from '../components/AppButton';
import BottomSheet from '../components/BottomSheet';
import AddressInput, { AddressInputResult } from '../components/AddressInput';
import { Cuisine } from '../models/restaurant';
import api from '../api';
import MichelinStar from '../assets/michelin.png';
import { UserPreferences } from '../models/user';

const initialState: UserPreferences = {
  pricePoints: [],
  cuisines: [],
  location: { name: '' },
  michelinStars: 0,
};

const Preferences = () => {
  const navigate = useNavigate();
  const [preferences, setPreferences] = useState<UserPreferences>();
  const theme = useTheme();
  const [showCuisinesPopup, setShowCuisinesPopup] = useState(false);
  const [showLocationPopup, setShowLocationPopup] = useState(false);
  const [cuisineOptions, setCuisineOptions] = useState<Cuisine[]>([]);

  useEffect(() => {
    api.cuisine.getAll().then((items) => {
      setCuisineOptions(items);
    });

    api.preferences.get().then((response) => {
      if ((response as any).statusCode === 500) {
        setPreferences(initialState);
        return;
      }

      setPreferences({
        ...response,
        cuisines: response.cuisines || [],
        pricePoints: response.pricePoints || [],
      });
    });
  }, []);

  useEffect(() => {
    if (!preferences) {
      return;
    }
    api.preferences.set({
      ...preferences,
      cuisines: preferences.cuisines || [],
      pricePoints: preferences.pricePoints || [],
    });
  }, [preferences]);

  const handlePricePointSelect = (value: number): void => {
    if (preferences?.pricePoints.includes(value)) {
      setPreferences(
        (prev) =>
          prev && {
            ...prev,
            pricePoints: prev.pricePoints.filter((item) => item !== value),
          },
      );
      return;
    }

    setPreferences(
      (prev) =>
        prev && {
          ...prev,
          pricePoints: [...prev.pricePoints, value],
        },
    );
  };

  const handleAddCuisine = () => {
    setShowCuisinesPopup(true);
  };

  const handleAddLocation = () => {
    setShowLocationPopup(true);
  };

  const handleAddressSelect = (address: AddressInputResult) => {
    setShowLocationPopup(false);
    setPreferences(
      (prev) =>
        prev && {
          ...prev,
          location: {
            name: address.address,
            lat: address.latitude,
            lng: address.longitude,
            rawJson: address.jsonMetadata,
          },
        },
    );
  };

  const handleCuisineClick = (cuisine: Cuisine) => {
    if (preferences?.cuisines.some((item) => item.id === cuisine.id)) {
      setPreferences(
        (prev) =>
          prev && {
            ...prev,
            cuisines: (prev.cuisines || []).filter((item) => item.id !== cuisine.id),
          },
      );
      return;
    }

    setPreferences(
      (prev) =>
        prev && {
          ...prev,
          cuisines: [...(prev.cuisines || []), cuisine],
        },
    );
    return;
  };

  const handleLocationClick = () => {
    setPreferences(
      (prev) =>
        prev && {
          ...prev,
          location: undefined,
        },
    );
    return;
  };

  const handleMichelinSelect = (value: number) => {
    setPreferences(
      (prev) =>
        prev && {
          ...prev,
          michelinStars: value === prev.michelinStars ? undefined : value,
        },
    );
  };

  const handleReset = () => {
    setPreferences(initialState);
  };

  return (
    <CardScreen title="Preferences" showBack onBackClick={() => navigate(-1)}>
      {!preferences && (
        <Box>
          <CircularProgress />
        </Box>
      )}
      {preferences && (
        <Box sx={{ mt: 3 }}>
          <SectionContainer title={'Neighbourhood'} enableAction onActionClick={handleAddLocation}>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  borderRadius: 2,
                  py: 0.25,
                  px: 2,
                  background: theme.palette.common.white,
                  cursor: preferences.location?.name ? 'pointer' : '',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={handleLocationClick}
              >
                <Typography variant="body2">{preferences.location?.name || 'Any'}</Typography>
                {preferences.location?.name && <Icon sx={{ ml: 1, fontSize: 16 }}>close</Icon>}
              </Box>
            </Box>
          </SectionContainer>
          <SectionContainer title={'Price'}>
            <PricePoints
              values={preferences?.pricePoints || []}
              onSelect={handlePricePointSelect}
            />
          </SectionContainer>
          <SectionContainer title={'Vibe'} enableAction onActionClick={handleAddCuisine}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {!(preferences.cuisines || []).length && (
                <Box
                  sx={{
                    borderRadius: 2,
                    py: 0.25,
                    px: 2,
                    background: theme.palette.common.white,
                  }}
                >
                  <Typography variant="body2">Any</Typography>
                </Box>
              )}
              {(preferences.cuisines || []).map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 2,
                    py: 0.25,
                    px: 2,
                    background: theme.palette.common.white,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCuisineClick(item)}
                >
                  <Typography variant="body2">{item.title}</Typography>{' '}
                  <Icon sx={{ ml: 1, fontSize: 16 }}>close</Icon>
                </Box>
              ))}
            </Box>
          </SectionContainer>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: 'left', fontWeight: 600, fontSize: 16, mt: 2, mb: 1 }}
          >
            Michelin stars
          </Typography>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mb: 2 }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                border:
                  preferences.michelinStars === 1
                    ? `1px solid ${theme.palette.primary.light}`
                    : '1px solid transparent',
                borderRadius: 2,
                background:
                  preferences.michelinStars === 1
                    ? alpha(theme.palette.primary.light, 0.15)
                    : 'none',
                p: 1,
              }}
              onClick={() => handleMichelinSelect(1)}
            >
              {[1].map((item) => (
                <Box
                  key={item}
                  component="img"
                  sx={{ width: 32, height: 32, objectFit: 'contain' }}
                  src={MichelinStar}
                />
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                border:
                  preferences.michelinStars === 2
                    ? `1px solid ${theme.palette.primary.light}`
                    : '1px solid transparent',
                borderRadius: 2,
                background:
                  preferences.michelinStars === 2
                    ? alpha(theme.palette.primary.light, 0.15)
                    : 'none',
                p: 1,
              }}
              onClick={() => handleMichelinSelect(2)}
            >
              {[1, 2].map((item) => (
                <Box
                  key={item}
                  component="img"
                  sx={{ width: 32, height: 32, objectFit: 'contain' }}
                  src={MichelinStar}
                />
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                border:
                  preferences.michelinStars === 3
                    ? `1px solid ${theme.palette.primary.light}`
                    : '1px solid transparent',
                borderRadius: 2,
                background:
                  preferences.michelinStars === 3
                    ? alpha(theme.palette.primary.light, 0.15)
                    : 'none',
                p: 1,
              }}
              onClick={() => handleMichelinSelect(3)}
            >
              {[1, 2, 3].map((item) => (
                <Box
                  key={item}
                  component="img"
                  sx={{ width: 32, height: 32, objectFit: 'contain' }}
                  src={MichelinStar}
                />
              ))}
            </Box>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body2"
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
              }}
              onClick={handleReset}
            >
              Reset to default
            </Typography>
          </Box>
          <AppButton onClick={() => navigate(-1)}>
            Discover <Icon sx={{ ml: 1 }}>arrow_forward</Icon>
          </AppButton>
        </Box>
      )}

      <BottomSheet isOpen={showCuisinesPopup} onClose={() => setShowCuisinesPopup(false)}>
        <Box p={2}>
          <Typography variant="h6" mb={2}>
            Vibes
          </Typography>
          {cuisineOptions.map((item) => (
            <Box
              key={item.id}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <MenuItem
                sx={{
                  width: '100%',
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={() => handleCuisineClick(item)}
              >
                <Typography>{item.title}</Typography>
                {(preferences?.cuisines || []).some(({ id }) => id === item.id) && (
                  <Icon>check_circle</Icon>
                )}
              </MenuItem>
            </Box>
          ))}
        </Box>
        <Box sx={{ mb: 2 }}>
          <AppButton onClick={() => setShowCuisinesPopup(false)}>Done</AppButton>
        </Box>
      </BottomSheet>
      <BottomSheet isOpen={showLocationPopup} onClose={() => setShowLocationPopup(false)}>
        <Box p={2}>
          <Typography variant="h6" mb={2}>
            Location
          </Typography>
          <AddressInput label="Choose location" onChange={handleAddressSelect} />
        </Box>
      </BottomSheet>
    </CardScreen>
  );
};

interface SectionContainerProps {
  title: string;
  children: React.ReactNode;
  enableAction?: boolean;
  onActionClick?(): void;
}

const SectionContainer: React.FC<SectionContainerProps> = ({
  title,
  children,
  enableAction,
  onActionClick,
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1,
          mt: 1,
        }}
      >
        <Typography variant="subtitle1" sx={{ textAlign: 'left', fontWeight: 600, fontSize: 16 }}>
          {title}
        </Typography>
        {enableAction && (
          <IconButton onClick={onActionClick}>
            <Icon sx={{ color: theme.palette.primary.main }}>add_circle</Icon>
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          background: alpha(theme.palette.primary.light, 0.15),
          borderRadius: 2,
          p: 2,
          mb: 2,
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default Preferences;
