import { createTheme } from '@mui/material/styles';

enum Color {
  Black = '#000',
  Grey = '#999',
  White = '#fff',
  Orange = '#F8973D',
  DarkOrange = '#E07008',

  Green = '#4DD393',
  Yellow = '#FFC700',
  Blue = '#5770C7',
  Red = '#E75F5F',
}

export const theme = createTheme({
  palette: {
    background: {
      // Overridden in index.css due to media queries not working for theme.palette
      default: Color.White,
    },
    primary: {
      main: Color.Black,
      light: Color.Blue,
    },
    secondary: {
      main: Color.Orange,
      dark: Color.DarkOrange,
    },
    common: {
      white: Color.White,
    },
    success: {
      main: Color.Green,
    },
    warning: {
      main: Color.Yellow,
    },
    error: {
      main: Color.Red,
    },
  },
  typography: {
    fontFamily: 'Inter',
    allVariants: {
      color: Color.Black,
    },
    h4: {
      fontSize: '1.75rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: '-6px',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          minWidth: 64,
          color: Color.Black,
          opacity: 0.6,
          '&.Mui-selected': {
            opacity: 1,
          },
        },
        iconOnly: {
          opacity: 0.2,
        },
        label: {
          fontSize: '0.8rem',
          opacity: 1,
          '&.Mui-selected': {
            fontSize: '0.8rem',
            fontWeight: 600,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          padding: '8px 20px',
          borderRadius: 48,
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '1rem',
        },
        text: {
          padding: '8px 20px',
          borderRadius: 48,
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '1rem',
        },
      },
    },
  },
});
