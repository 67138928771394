import { Sentiment } from '../models/restaurant';

const paths = {
  login: '/login',
  signup: '/signup',
  passwordForgot: '/password/forgot',
  passwordReset: '/password/reset',
  emailConfirm: '/email/confirm',
  emailConfirmTrigger: '/email/confirm/trigger',
  discover: '/discover',
  discoverPreferences: '/discover/preferences',
  profile: '/profile',
  profileEdit: '/profile/edit',
  lists: '/profile/lists',
  people: '/people',
  peopleGroup: '/people/group',
  adminDashboard: '/admin',
  restaurantRaw: '/admin/restaurant/:id',
  restaurants: '/admin/restaurants',
  vibes: '/admin/vibes',
  restaurantPathBuilder: (id: string) => `/admin/restaurant/${id || 'new'}`,
  listsPathBuilder: (type: Sentiment) => `/profile/lists?type=${type}`,
  home: '/',
};

export default paths;
