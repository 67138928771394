import { alpha, Box, Typography, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import useScreenWidth from '../hooks/useScreenWidth';
import { AddCircleRounded } from '@mui/icons-material';

interface FileInputProps {
  fileUrl: string;
  label?: string;
  onChange(file: File): void;
}

export const allowedFileTypes = ['.png', '.jpg', '.jpeg'];
export const allowedFileTypesDesktop = [...allowedFileTypes, '.jfif'];

const FileInput = ({ label, fileUrl, onChange }: FileInputProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const screenWidth = useScreenWidth();

  const fileTypes =
    screenWidth < theme.breakpoints.values.md ? allowedFileTypes : allowedFileTypesDesktop;

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      onChange(file);
    }
  };

  return (
    <Box mb={1}>
      {label && (
        <Typography sx={{ marginBottom: 1 }} textAlign="center">
          {label}
        </Typography>
      )}
      <input
        type="file"
        accept={fileTypes.join(',')}
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {fileUrl ? (
        <Box
          component="img"
          sx={{
            width: 160,
            height: 160,
            background: alpha(theme.palette.primary.main, 0.1),
            objectFit: 'contain',
            borderRadius: 1,
          }}
          src={fileUrl}
        />
      ) : (
        <Box
          sx={{
            background: alpha(theme.palette.primary.main, 0.05),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: 160,
            height: 160,
            borderRadius: 1,
            cursor: 'pointer',

            '&:hover': {
              border: `2px solid ${theme.palette.secondary.main}`,
            },
          }}
          onClick={handleButtonClick}
        >
          <Box
            component="button"
            sx={{ marginTop: 1, border: 'none', cursor: 'pointer', background: 'none' }}
            type="button"
          >
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              Add <AddCircleRounded />
            </Typography>
          </Box>
        </Box>
      )}
      {fileUrl && (
        <Box
          sx={{
            marginTop: 1,
            border: 'none',
            cursor: 'pointer',
            background: 'none',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          onClick={handleButtonClick}
        >
          <Typography sx={{ textDecoration: 'underline' }} fontWeight={600} variant="caption">
            change image
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FileInput;
