import { useEffect, useState } from 'react';

import {
  Box,
  Divider,
  Icon,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';

import api from '../api';
import { Restaurant, Sentiment } from '../models/restaurant';
import BottomSheet from '../components/BottomSheet';
import useNotification from '../state/useNotification';
import { useNavigate } from 'react-router-dom';
import paths from '../routing/paths';
import AppButton from '../components/AppButton';
import RatingStars from '../components/RatingStars';
import { CurrencySymbol } from '../constants/currencies';
import MichelinStar from '../assets/michelin.png';
import useScreenWidth from '../hooks/useScreenWidth';

enum Action {
  Unknown,
  NotInterested,
  Skip,
  KeenToGo,
}

interface ActionItem {
  action: Action;
  iconText: string;
  iconColor: string;
  tooltip: string;
}

const buildActions = (theme: Theme): ActionItem[] => {
  return [
    {
      action: Action.NotInterested,
      iconText: 'close',
      iconColor: theme.palette.error.main,
      tooltip: `I'm not interested`,
    },
    {
      action: Action.Skip,
      iconText: 'redo',
      iconColor: theme.palette.primary.light,
      tooltip: 'Skip',
    },
    {
      action: Action.KeenToGo,
      iconText: 'favorite',
      iconColor: theme.palette.success.main,
      tooltip: `I'm keen to go`,
    },
  ];
};

const Discover = () => {
  const theme = useTheme();
  const [items, setItems] = useState<Restaurant[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [actions] = useState(buildActions(theme));
  const [, setNotification] = useNotification();
  const [chosenAction, setChosenAction] = useState<ActionItem>();
  const [isDetailsPopupOpen, setIsDetailsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledIds, setDisabledIds] = useState<{ [id: string]: boolean }>({});
  const [currentRestaurant, setCurrentRestaurant] = useState<Restaurant>();
  const screenWidth = useScreenWidth();
  const [currentRestaurantImagesIndex, setCurrentRestaurantImagesIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    api.restaurant.discover().then((response) => {
      setItems(response);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (items && items[currentIndex]) {
      setCurrentRestaurant(undefined);
      setCurrentRestaurantImagesIndex(0);
      api.restaurant.getById(items[currentIndex].id || '').then((data) => {
        setCurrentRestaurant(data);
      });
    }
  }, [items, currentIndex]);

  const restaurant = items[currentIndex];

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === items.length - 1 ? 0 : prev + 1));
  };

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev === 0 ? items.length - 1 : prev - 1));
  };

  const handlePreferencesClick = () => {
    navigate(paths.discoverPreferences);
  };

  const handleAction = (selection: ActionItem) => {
    if (chosenAction) {
      return;
    }
    if (selection.action === Action.Skip) {
      handleNext();
      return;
    }
    const sentiment = mapSentiment(selection.action);
    if (!sentiment) {
      return;
    }
    if (disabledIds[items[currentIndex].id as string]) {
      setNotification({ message: 'Action already taken for this item' });
      return;
    }
    setChosenAction(selection);
    setTimeout(async () => {
      await api.sentiment.create({
        restaurantId: items[currentIndex].id as string,
        sentiment: sentiment,
      });
      setDisabledIds((prev) => ({
        ...prev,
        [items[currentIndex].id as string]: true,
      }));
      setChosenAction(undefined);
      handleNext();
    }, 2000);
  };

  const handleInfoClick = () => {
    setIsDetailsPopupOpen(true);
  };

  const handleNextImage = () => {
    if (!currentRestaurant) {
      return;
    }
    console.log(currentRestaurant?.images.length);
    console.log(currentRestaurantImagesIndex);
    setCurrentRestaurantImagesIndex((prev) =>
      prev + 1 > currentRestaurant?.images.length ? 0 : prev + 1,
    );
  };

  const handlePrevImage = () => {
    if (!currentRestaurant) {
      return;
    }
    setCurrentRestaurantImagesIndex((prev) =>
      prev - 1 < 0 ? currentRestaurant?.images.length : prev - 1,
    );
  };

  const mapSentiment = (action: Action): Sentiment => {
    if (action === Action.KeenToGo) return Sentiment.KeenToGo;
    if (action === Action.NotInterested) return Sentiment.NotInterested;
    return Sentiment.Unknown;
  };

  return (
    <Box
      sx={{
        mt: 8,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          bottom: 24,
        },
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          flex: 1,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          maxWidth: 480,
          borderRadius: 2,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          [theme.breakpoints.up('sm')]: {
            borderRadius: 2,
          },
        }}
      >
        {items.length === 0 && !isLoading && (
          <Box
            mt={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: theme.palette.grey[500], textAlign: 'center', maxWidth: 400 }}
            >
              We have run out of great restaurants for you to discover for now. Try changing your
              preferences or check in a bit later!
            </Typography>
            <AppButton onClick={handlePreferencesClick}>Change preferences</AppButton>
          </Box>
        )}
        {restaurant && (
          <>
            <TopIcons
              theme={theme}
              onPreferencesClick={handlePreferencesClick}
              onPreviousClick={handlePrevious}
            />
            <Box
              sx={{
                width: '100%',
                background: theme.palette.common.black,
                backgroundImage: `url(${restaurant.imageUrl})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                flex: 1,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  background: 'linear-gradient(rgba(0,0,0,0) 25%, rgba(0,0,0,1))',
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: -2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Box position="relative" width="100%">
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mb: 1,
                      fontWeight: 500,
                    }}
                    variant="h6"
                    color={theme.palette.common.white}
                  >
                    {restaurant.title}
                  </Typography>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 32,
                      background: alpha(theme.palette.common.black, 0.3),
                      borderRadius: 8,
                      cursor: 'pointer',
                      zIndex: 10,
                    }}
                  >
                    <Tooltip title="More information">
                      <IconButton size="small" onClick={handleInfoClick}>
                        <Icon sx={{ color: theme.palette.common.white }}>info</Icon>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <Box mb={1}>
                  <RatingStars value={restaurant.rating || 0} />
                </Box>
                <Typography
                  variant="body2"
                  color={theme.palette.grey[400]}
                  sx={{ display: 'flex', justifyContent: 'center', mb: 2, px: 2 }}
                >
                  <Icon sx={{ mr: 1 }}>location_on</Icon>
                  {restaurant.addressFull}
                </Typography>
                {Boolean(restaurant.quotes.length) && (
                  <Typography
                    variant="body2"
                    color={theme.palette.grey[400]}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mb: 2,
                      px: 2,
                      textAlign: 'center',
                    }}
                  >
                    <i>
                      "{restaurant.quotes[0].text}"{' '}
                      {restaurant.quotes[0].author && `- ${restaurant.quotes[0].author}`}
                    </i>
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: chosenAction ? 200 : 0,
                      height: chosenAction ? 200 : 0,
                      borderRadius: '50%',
                      background: alpha(theme.palette.common.black, 0.3),
                      transition: 'width 200ms ease-in-out, height 200ms ease-in-out',
                    }}
                  >
                    <Icon
                      sx={{
                        color: chosenAction?.iconColor,
                        fontSize: chosenAction ? '120px' : 0,
                        transition: 'font-size 200ms ease-in-out',
                      }}
                    >
                      {chosenAction?.iconText}
                    </Icon>
                  </Box>
                }
              </Box>
            </Box>
            <BottomActionBar theme={theme} actions={actions} onActionClick={handleAction} />
          </>
        )}
      </Box>
      <BottomSheet
        isOpen={isDetailsPopupOpen}
        fullScreen={screenWidth < theme.breakpoints.values.md}
        maxWidth={600}
        onClose={() => setIsDetailsPopupOpen(false)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 12, right: 12 }}
            onClick={() => setIsDetailsPopupOpen(false)}
          >
            <Icon>close</Icon>
          </IconButton>
          {restaurant && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                p: 2,
              }}
            >
              <Typography variant="h6" fontWeight={500} mb={0}>
                {restaurant.title}
              </Typography>
              <Box>
                <RatingStars value={restaurant.rating || 0} />
              </Box>
            </Box>
          )}
          {currentRestaurant && (
            <Box sx={{ width: '100%' }}>
              <Typography
                variant="body2"
                color={theme.palette.grey[600]}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  px: 2,
                  pb: 2,
                  mt: -1,
                  fontSize: 14,
                }}
              >
                <Icon sx={{ mr: 1 }}>location_on</Icon>
                {restaurant.addressFull}
              </Typography>
              <Box sx={{ width: '100%', position: 'relative', mb: 1 }}>
                <Box
                  component="img"
                  sx={{ width: '100%', height: '240px', objectFit: 'cover' }}
                  src={
                    [restaurant.imageUrl, ...currentRestaurant.images.map((image) => image.url)][
                      currentRestaurantImagesIndex
                    ]
                  }
                />
                <Box
                  sx={{
                    position: 'absolute',
                    left: 8,
                    right: 8,
                    top: 0,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    sx={{
                      background: alpha(theme.palette.common.white, 0.6),
                      '&:hover': {
                        background: alpha(theme.palette.common.white, 0.9),
                      },
                    }}
                    onClick={handlePrevImage}
                  >
                    <Icon sx={{ color: theme.palette.primary.main }}>arrow_back</Icon>
                  </IconButton>
                  <IconButton
                    sx={{
                      background: alpha(theme.palette.common.white, 0.6),
                      '&:hover': {
                        background: alpha(theme.palette.common.white, 0.9),
                      },
                    }}
                    onClick={handleNextImage}
                  >
                    <Icon sx={{ color: theme.palette.primary.main }}>arrow_forward</Icon>
                  </IconButton>
                </Box>
              </Box>
              {Boolean(currentRestaurant.cuisines?.length) && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: '100%',
                    gap: 1,
                    mb: 1,
                    px: 2,
                  }}
                >
                  {currentRestaurant.cuisines.map((item) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 2,
                        py: 0.25,
                        px: 2,
                        background: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        fontSize: 14,
                      }}
                      key={item.id}
                    >
                      {item.title}
                    </Box>
                  ))}
                </Box>
              )}
              {currentRestaurant.pricePoint && (
                <Box
                  mb={2}
                  mt={2}
                  sx={{
                    px: 2,
                    width: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      border: `1px solid ${theme.palette.grey[500]}`,
                      borderRadius: 4,
                      width: 'fit-content',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      fontWeight: 500,
                      px: 1,
                      py: 0.2,
                    }}
                  >
                    <Icon sx={{ mr: 1, fontSize: 18 }}>local_offer</Icon>
                    {CurrencySymbol.Gbp.repeat(currentRestaurant.pricePoint)}
                  </Typography>
                </Box>
              )}
              {Boolean(currentRestaurant?.michelinStars) && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                    alignItems: 'center',
                    borderRadius: 2,
                    px: 2,
                    pt: 0,
                    gap: 0.5,
                    mb: 2,
                  }}
                >
                  {new Array(currentRestaurant?.michelinStars).fill(0).map((_, i) => (
                    <Box
                      key={i}
                      component="img"
                      sx={{ width: 28, height: 28, objectFit: 'contain' }}
                      src={MichelinStar}
                    />
                  ))}
                </Box>
              )}
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: 'left',
                  width: '100%',
                  px: 2,
                  mb: 2,
                  mt: 1,
                }}
              >
                About
              </Typography>
              <Box
                sx={{
                  maxWidth: '100%',
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  px: 2,
                  mb: 3,
                }}
              >
                <Typography
                  sx={{ color: theme.palette.grey[600], whiteSpace: 'pre-line' }}
                  component="pre"
                  variant="body2"
                >
                  {currentRestaurant.description}
                </Typography>
              </Box>
              {Boolean(currentRestaurant.quotes.length) && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Divider sx={{ width: '80%' }} />
                  <Box sx={{ mt: 3, mb: 2, width: '100%', px: 2 }}>
                    {currentRestaurant.quotes.map((item) => (
                      <Box
                        key={item.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          textAlign: 'center',
                          mb: 1,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ color: theme.palette.grey[600], fontWeight: 400 }}
                        >
                          <i>"{item.text}"</i>
                          {item.author && ` - ${item.author}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </BottomSheet>
    </Box>
  );
};

const TopIcons = ({
  theme,
  onPreviousClick,
  onPreferencesClick,
}: {
  theme: Theme;
  onPreviousClick(): void;
  onPreferencesClick(): void;
}) => (
  <>
    <Box
      sx={{
        position: 'absolute',
        top: 24,
        left: 24,
        background: alpha(theme.palette.common.black, 0.6),
        borderRadius: 8,
        cursor: 'pointer',
        zIndex: 10,
      }}
    >
      <Tooltip title="Previous">
        <IconButton size="small" onClick={onPreviousClick}>
          <Icon sx={{ color: theme.palette.common.white }}>replay</Icon>
        </IconButton>
      </Tooltip>
    </Box>
    <Box
      sx={{
        position: 'absolute',
        top: 24,
        right: 24,
        background: alpha(theme.palette.common.black, 0.6),
        borderRadius: 8,
        cursor: 'pointer',
        zIndex: 10,
      }}
    >
      <Tooltip title="Preferences">
        <IconButton size="small" onClick={onPreferencesClick}>
          <Icon sx={{ color: theme.palette.common.white }}>settings</Icon>
        </IconButton>
      </Tooltip>
    </Box>
  </>
);

const BottomActionBar = ({
  theme,
  actions,
  onActionClick,
}: {
  theme: Theme;
  actions: ActionItem[];
  onActionClick(action: ActionItem): void;
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      gap: 4,
      alignItems: 'center',
      background: theme.palette.common.black,
      py: 2,
      width: '100%',
    }}
  >
    {actions.map((actionItem) => (
      <Tooltip title={actionItem.tooltip} key={actionItem.action}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 48,
            height: 48,
            borderRadius: '50%',
            background: alpha(theme.palette.common.white, 0.2),
            transition: 'background 50ms ease-in-out',
            cursor: 'pointer',
            '&:hover': {
              background: alpha(theme.palette.common.white, 0.3),
            },
          }}
          onClick={() => onActionClick(actionItem)}
        >
          <Icon sx={{ color: actionItem.iconColor }}>{actionItem.iconText}</Icon>
        </Box>
      </Tooltip>
    ))}
  </Box>
);

export default Discover;
