import { Box, Typography, useTheme, alpha, Icon, Divider } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../../api';
import AppButton from '../../components/AppButton';
import CardScreen from '../../components/CardScreen';
import paths from '../../routing/paths';
import { GlobalContext } from '../../state/GlobalContext';
import useUser from '../../state/useUser';
import { Link } from 'react-router-dom';
import { UserType } from '../../models/user';
import { Sentiment } from '../../models/restaurant';

const Profile = () => {
  const [user] = useUser();
  const { logout } = useContext(GlobalContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLogout = async () => {
    await api.auth.logout();
    logout();
  };

  const handleConfirmEmail = () => {
    navigate(paths.emailConfirmTrigger);
  };

  return (
    <CardScreen title="Profile">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="caption" sx={{ color: theme.palette.grey[500] }}>
            Username
          </Typography>
          <Typography variant="body1" mt={-0.5} mb={0.5} fontWeight={500}>
            {user.handle}
          </Typography>
          <Typography variant="caption" sx={{ color: theme.palette.grey[500] }}>
            Full name
          </Typography>
          <Typography variant="body1" mt={-0.5} mb={0.5} fontWeight={500}>
            {user.firstName}
          </Typography>
          <Typography variant="caption" sx={{ color: theme.palette.grey[500] }}>
            Email
          </Typography>
          <Typography variant="body1" mt={-0.5} fontWeight={500}>
            {user.email}
          </Typography>
        </Box>
        {user.profilePictureUrl && (
          <Box
            component="img"
            src={user.profilePictureUrl}
            sx={{
              width: 100,
              height: 100,
              borderRadius: '50%',
              mb: 0,
              mt: 1,
              objectFit: 'cover',
              background: alpha(theme.palette.primary.main, 0.1),
            }}
          />
        )}
      </Box>
      {!user.emailConfirmed && (
        <>
          <Box mt={2} mb={4}>
            <AppButton onClick={handleConfirmEmail}>Verify email</AppButton>
          </Box>
        </>
      )}
      <Box mt={2} mb={2}>
        <AppButton color="text" onClick={() => navigate(paths.profileEdit)}>
          <>
            <Typography mr={1} fontSize={16} fontWeight={600}>
              Edit
            </Typography>
            <Icon color="primary">edit</Icon>
          </>
        </AppButton>
      </Box>
      <Divider />
      <Box mt={3} mb={2}>
        <Box mb={2}>
          <Link to={paths.lists} style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography>Keen list</Typography>
              <Icon sx={{ ml: 1, color: theme.palette.success.main }}>favorite</Icon>
            </Box>
          </Link>
        </Box>
        <Box mb={2}>
          <Link
            to={paths.listsPathBuilder(Sentiment.NotInterested)}
            style={{ textDecoration: 'none' }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography> Not interested list</Typography>
              <Icon sx={{ ml: 1, color: theme.palette.error.main }}>close</Icon>
            </Box>
          </Link>
        </Box>
        {user.userType === UserType.Admin && (
          <>
            <Divider />
            <Box mt={2}>
              <Link to={paths.adminDashboard} target="_blank" style={{ textDecoration: 'none' }}>
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Typography>Admin dashboard</Typography>{' '}
                  <Icon sx={{ ml: 1, color: theme.palette.primary.main }}>launch</Icon>
                </Box>
              </Link>
            </Box>
          </>
        )}
      </Box>
      <Divider />
      <Box mt={2}>
        <AppButton color="text" onClick={handleLogout}>
          Logout
        </AppButton>
      </Box>
    </CardScreen>
  );
};

export default Profile;
