import {
  Box,
  Button,
  CircularProgress,
  Icon,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CardScreen from '../../components/CardScreen';
import useUser from '../../state/useUser';
import Input from '../../components/Input';
import api from '../../api';
import { UserSearchResult } from '../../models/user';
import { SEARCH_DEBOUNCE } from '../../constants/debounce';
import BottomSheet from '../../components/BottomSheet';
import UserCard from './UserCard';
import paths from '../../routing/paths';

enum TabValue {
  Followers,
  Following,
}

const People = () => {
  const [user] = useUser();
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [searchResults, setSearchResults] = useState<UserSearchResult[]>([]);
  const [followers, setFollowers] = useState<UserSearchResult[]>([]);
  const [following, setFollowing] = useState<UserSearchResult[]>([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(TabValue.Followers);

  useEffect(() => {
    const typingTimeout = setTimeout(async () => {
      setIsSearching(true);
      setSearchResults([]);
      const results = await api.user.search(search);
      setSearchResults(results.filter((x) => x.id !== user.id));
      setIsSearching(false);
    }, SEARCH_DEBOUNCE);

    return () => clearTimeout(typingTimeout);
  }, [search]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([api.user.getFollowers(), api.user.getFollowing()]).then(
      ([followersResponse, followingResponse]) => {
        setFollowers(followersResponse);
        setFollowing(followingResponse);
        setIsLoading(false);
      },
    );
  }, []);

  const handleFindClick = () => {
    setShowSearchPopup(true);
  };

  const handleUnfollowClick = async (userId: string) => {
    await api.user.unfollow(userId);
    setFollowing((prev) => prev.filter(({ id }) => id !== userId));
  };

  const handleFollowClick = async (user: UserSearchResult) => {
    setFollowing((prev) =>
      [...prev, user]
        .filter((item, i, items) => items.findIndex((x) => x.id === item.id) === i)
        .sort((a, b) => (a.handle > b.handle ? 1 : -1)),
    );
    await api.user.follow(user.id);
    const response = await api.user.getFollowing();
    setFollowing(response);
  };

  const handleAddGroupClick = () => {
    navigate(paths.peopleGroup);
  };

  return (
    <CardScreen>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <Box onClick={handleFindClick}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: 'pointer',
              color: theme.palette.primary.main,
            }}
            color="inherit"
          >
            Follow <Icon>search</Icon>
          </Typography>
        </Box>
        {Boolean(following.length) && (
          <Button
            variant="contained"
            disableElevation
            sx={{ height: 32 }}
            onClick={handleAddGroupClick}
          >
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, whiteSpace: 'nowrap' }}
              color="inherit"
            >
              Start a dinner party
            </Typography>
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        {[
          { count: followers.length, text: 'Followers', value: TabValue.Followers },
          { count: following.length, text: 'Following', value: TabValue.Following },
        ].map((item) => (
          <Box
            key={item.text}
            onClick={() => setTabValue(item.value)}
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              cursor: 'pointer',
              mb: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 14,
                color: theme.palette.grey[500],
                //tabValue === item.value ? theme.palette.primary.main : theme.palette.grey[500],
              }}
            >
              {item.count}
            </Typography>
            <Typography
              sx={{
                color:
                  tabValue === item.value ? theme.palette.primary.main : theme.palette.grey[500],
                fontWeight: tabValue === item.value ? 500 : 400,
              }}
            >
              {item.text}
            </Typography>
            {tabValue === item.value && (
              <Box
                sx={{
                  mt: 1,
                  position: 'absolute',
                  height: 3,
                  background: theme.palette.primary.main,
                  width: '100%',
                  borderRadius: 1,
                  bottom: -6,
                }}
              />
            )}
          </Box>
        ))}
      </Box>
      {isLoading && (
        <Box mt={2}>
          <CircularProgress />
        </Box>
      )}
      {tabValue === TabValue.Followers && (
        <Box mt={3}>
          {!followers.length && !isLoading && (
            <Typography sx={{ fontSize: 14 }} color={theme.palette.grey[500]}>
              You don't have any followers
            </Typography>
          )}
          {followers.map((user) => (
            <UserCard key={user.id} user={user} />
          ))}
        </Box>
      )}

      {tabValue === TabValue.Following && (
        <Box mt={3}>
          {!following.length && !isLoading && (
            <Typography sx={{ fontSize: 14 }} color={theme.palette.grey[500]}>
              You are not following anyone
            </Typography>
          )}
          {following.map((user) => (
            <UserCard
              key={user.id}
              user={user}
              actions={
                <Box onClick={() => handleUnfollowClick(user.id)}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      cursor: 'pointer',
                    }}
                    color="inherit"
                  >
                    Unfollow
                  </Typography>
                </Box>
              }
            />
          ))}
        </Box>
      )}
      <BottomSheet isOpen={showSearchPopup} onClose={() => setShowSearchPopup(false)}>
        <Box p={2}>
          <Input label="Search people" value={search} onChange={setSearch} />
          {isSearching && <CircularProgress size={20} />}
          {!isSearching && !!search && !searchResults.length && (
            <Box>
              <Typography sx={{ fontSize: 14 }} color={theme.palette.grey[500]}>
                No people found
              </Typography>
            </Box>
          )}
          {searchResults.map((user) => (
            <UserCard
              key={user.id}
              user={user}
              actions={
                <Box
                  onClick={() => {
                    handleFollowClick(user);
                    setShowSearchPopup(false);
                    setTabValue(TabValue.Following);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      cursor: 'pointer',
                    }}
                    color="inherit"
                  >
                    Follow +
                  </Typography>
                </Box>
              }
            />
          ))}
        </Box>
      </BottomSheet>
    </CardScreen>
  );
};

export default People;
