import React, { useState } from 'react';
import { Box, Button, Modal, Typography, TextField } from '@mui/material';
import AppButton from '../../components/AppButton';
import api from '../../api';

const BulkUploadModal: React.FC<{ afterBulkUploadSuccess(): void }> = ({
  afterBulkUploadSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (isLoading) {
      return;
    }
    if (file) {
      setIsLoading(true);
      await api.restaurant.bulkUpload(file);
      afterBulkUploadSuccess && afterBulkUploadSuccess();
      setIsLoading(false);
      handleClose();
    } else {
      alert('Please select a file to upload.');
    }
  };

  return (
    <div>
      <AppButton onClick={handleOpen}>Bulk Upload</AppButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="file-upload-modal"
        aria-describedby="file-upload-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="file-upload-modal" variant="h6" component="h2">
            Upload File
          </Typography>
          <TextField
            type="file"
            fullWidth
            variant="outlined"
            onChange={handleFileChange}
            sx={{ mt: 2 }}
          />
          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Button onClick={handleClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <AppButton isLoading={isLoading} onClick={handleUpload}>
              Upload
            </AppButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BulkUploadModal;
