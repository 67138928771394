import { UpdateUserRequest, User, UserSearchResult } from '../models/user';
import apiClient from './apiClient';

const prefix = '/users';

const user = {
  getMe: async (): Promise<User> => await apiClient.get(`${prefix}/me`),
  updateMe: async (data: UpdateUserRequest, file?: File): Promise<{ success: boolean }> => {
    const formData = new FormData();
    if (file) {
      formData.append('image', file);
    }
    formData.append('data', JSON.stringify(data));

    return await apiClient.putFormData(`${prefix}/me`, formData);
  },
  getUser: async (username: string): Promise<User> =>
    await apiClient.get(`${prefix}/by-name/${username}`),
  search: async (search: string): Promise<UserSearchResult[]> =>
    await apiClient.get(`${prefix}/search?query=${search}`),
  getFollowers: async (): Promise<UserSearchResult[]> => await apiClient.get(`${prefix}/followers`),
  getFollowing: async (): Promise<UserSearchResult[]> => await apiClient.get(`${prefix}/following`),
  follow: async (id: string): Promise<void> => await apiClient.post(`${prefix}/follow/${id}`, {}),
  unfollow: async (id: string): Promise<boolean> =>
    await apiClient.delete(`${prefix}/unfollow/${id}`),
};

export default user;
