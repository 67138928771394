import { useEffect, useState } from 'react';
import api from '../../api';
import { Cuisine } from '../../models/restaurant';
import { Box, Icon, Typography, useTheme } from '@mui/material';
import AppButton from '../../components/AppButton';
import useNotification from '../../state/useNotification';
import BottomSheet from '../../components/BottomSheet';
import Input from '../../components/Input';

const sortAlphabetically = (items: { title: string }[]) =>
  [...items].sort((a, b) => (a.title > b.title ? 1 : -1));

const Vibes = () => {
  const [items, setItems] = useState<Cuisine[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedItem, setSelectedItem] = useState<Cuisine>();
  const [submitted, setSubmitted] = useState<boolean>();
  const theme = useTheme();
  const [, setNotification] = useNotification();

  useEffect(() => {
    api.cuisine.getAll().then((items) => {
      setItems(sortAlphabetically(items));
    });
  }, []);

  useEffect(() => {
    setIsPopupOpen(!!selectedItem);
    setTitle(selectedItem?.title || '');
  }, [selectedItem]);

  const handleDelete = async (id: string) => {
    const response = await api.cuisine.delete(id);
    if (!response) {
      setNotification({ message: 'Cuisine is linked to a restaurat. Unlink before deleting' });
      return;
    }
    setItems((items) => items.filter((item) => item.id !== id));
    setNotification({ message: 'Cuisine deleted' });
  };

  const handleAdd = () => {
    setIsPopupOpen(true);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setSubmitted(true);

    if (!title) {
      return;
    }

    setIsSaving(true);

    if (!selectedItem) {
      const response = await api.cuisine.create({ title });
      setIsSaving(false);

      if (!response.id) {
        setNotification({ message: 'Invalid cuisine' });
        return;
      }

      setItems((prev) => sortAlphabetically([...prev, { id: response.id, title }]));
      setIsPopupOpen(false);
      setTitle('');
      setSubmitted(false);
      return;
    }

    const response = await api.cuisine.update({ id: selectedItem?.id, title });
    setIsSaving(false);

    if (!response.id) {
      setNotification({ message: 'Invalid cuisine' });
      return;
    }

    setSubmitted(false);
    setItems((prev) =>
      sortAlphabetically(
        prev.map((item) => (item.id === selectedItem.id ? { ...selectedItem, title } : item)),
      ),
    );
    setSelectedItem(undefined);
    return;
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">Vibe list {!!items.length && `(${items.length})`}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mb: 2 }}>
        <AppButton onClick={handleAdd}>
          Add new <Icon sx={{ ml: 0.5 }}>add</Icon>
        </AppButton>
      </Box>
      <Box>
        {items.map((item) => (
          <Box
            key={item.id}
            sx={{
              p: 0.5,
              mb: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
                <Typography variant="body1">{item.title}</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
              <Typography sx={{ cursor: 'pointer' }} onClick={() => setSelectedItem(item)}>
                Edit
              </Typography>
              <Typography
                sx={{ cursor: 'pointer' }}
                color="error"
                onClick={() => handleDelete(item.id as string)}
              >
                Delete
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <BottomSheet isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
        <Box p={2}>
          <Typography variant="h6">{selectedItem ? 'Edit' : 'Add'} vibe</Typography>
          <Box
            component="form"
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '100%',
              mt: 3,
            }}
            onSubmit={handleSubmit}
          >
            <Input
              label="Title"
              name="title"
              value={title}
              onChange={setTitle}
              error="required"
              isError={submitted && !title}
            />
            <AppButton type="submit" isLoading={isSaving}>
              Save
            </AppButton>
          </Box>
        </Box>
      </BottomSheet>
    </Box>
  );
};

export default Vibes;
