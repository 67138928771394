import {
  AuthResponse,
  EmailConfirmationRequest,
  LoginRequest,
  PasswordResetRequest,
  PasswordResetTriggerRequest,
  SignupRequest,
} from '../models/api';
import apiClient from './apiClient';

const auth = {
  login: async (request: LoginRequest): Promise<AuthResponse> =>
    await apiClient.post('/auth/login', request),
  signup: async (request: SignupRequest): Promise<AuthResponse> =>
    await apiClient.post('/auth/signup', request),
  logout: async (): Promise<void> => await apiClient.post('/auth/logout', {}),
  triggerResetPassword: async (request: PasswordResetTriggerRequest): Promise<AuthResponse> =>
    await apiClient.post('/auth/password/reset/trigger', request),
  resetPassword: async (request: PasswordResetRequest): Promise<AuthResponse> =>
    await apiClient.post('/auth/password/reset', request),
  triggerConfirmEmail: async (): Promise<AuthResponse> =>
    await apiClient.post('/auth/email/confirm/trigger', {}),
  confirmEmail: async (request: EmailConfirmationRequest): Promise<AuthResponse> =>
    await apiClient.post('/auth/email/confirm', request),
};

export default auth;
