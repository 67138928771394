import { Icon, IconButton, InputAdornment, TextField, Typography } from '@mui/material';

interface InputProps {
  value: any;
  label?: string;
  name?: string;
  isError?: boolean;
  error?: string;
  placeholder?: string;
  hint?: string;
  type?: 'text' | 'password' | 'number';
  smallFont?: boolean;
  multiline?: boolean;
  isClearable?: boolean;
  onChange(value: any): void;
}

const Input = ({
  value,
  label,
  name,
  placeholder,
  type,
  isError,
  error,
  smallFont,
  hint,
  isClearable,
  multiline,
  onChange,
}: InputProps) => (
  <>
    <TextField
      sx={{
        marginBottom: 2,
        width: '100%',
      }}
      label={label}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      multiline={multiline}
      helperText={isError && error}
      error={isError}
      type={type || 'text'}
      inputProps={{
        style: { fontSize: smallFont ? 14 : undefined },
      }}
      InputProps={{
        endAdornment: isClearable && (
          <InputAdornment position="end">
            {value && (
              <IconButton
                color="primary"
                size={'small'}
                sx={{ mr: 0.2, fontSize: 10 }}
                edge="end"
                onClick={() => onChange('')}
              >
                <Icon>clear</Icon>
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
    {hint && (
      <Typography
        sx={{ opacity: 0.7, width: '100%', ml: 3 }}
        variant="caption"
        textAlign="left"
        mt={-2}
        mb={2}
      >
        {hint}
      </Typography>
    )}
  </>
);

export default Input;
