import { Cuisine } from '../models/restaurant';
import apiClient from './apiClient';

const prefix = '/cuisine';

const cuisine = {
  create: async (data: Cuisine): Promise<{ id: string }> => await apiClient.post(`${prefix}`, data),
  getAll: async (): Promise<Cuisine[]> => await apiClient.get(`${prefix}`),
  update: async (data: Cuisine): Promise<{ id: string }> =>
    await apiClient.put(`${prefix}/${data.id}`, data),
  delete: async (id: string): Promise<boolean> => await apiClient.delete(`${prefix}/${id}`),
};

export default cuisine;
