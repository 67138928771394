import { createBrowserRouter, Navigate } from 'react-router-dom';

import paths from './paths';
import Login from '../screens/Login';
import Signup from '../screens/Signup';
import ProtectedScreens from '../screens/ProtectedScreens';
import ForgotPassword from '../screens/ForgotPassword';
import ResetPassword from '../screens/ResetPassword';
import ConfirmEmail from '../screens/ConfirmEmail';
import ConfirmEmailTrigger from '../screens/ConfirmEmailTrigger';
import Discover from '../screens/Discover';
import Profile from '../screens/profile/Profile';
import EditProfile from '../screens/profile/EditProfile';
import Restaurants from '../screens/admin/Restaurants';
import RestaurantAddEdit from '../screens/admin/RestaurantAddEdit';
import Vibes from '../screens/admin/Vibes';
import Preferences from '../screens/Preferences';
import UserLists from '../screens/UserLists';
import People from '../screens/people/People';
import Group from '../screens/people/Group';
import Root from './Root';

const router = createBrowserRouter([
  {
    path: paths.home,
    element: <Root />,
    children: [
      { path: paths.login, element: <Login /> },
      { path: paths.signup, element: <Signup /> },
      { path: paths.passwordForgot, element: <ForgotPassword /> },
      { path: paths.passwordReset, element: <ResetPassword /> },
      { path: paths.emailConfirm, element: <ConfirmEmail /> },
      {
        element: <ProtectedScreens />,
        children: [
          { path: paths.profileEdit, element: <EditProfile /> },
          { path: paths.emailConfirmTrigger, element: <ConfirmEmailTrigger /> },
          { path: paths.discover, element: <Discover /> },
          { path: paths.discoverPreferences, element: <Preferences /> },
          { path: paths.profile, element: <Profile /> },
          { path: paths.lists, element: <UserLists /> },
          { path: paths.people, element: <People /> },
          { path: paths.peopleGroup, element: <Group /> },
          {
            element: <ProtectedScreens admin />,
            children: [
              { path: paths.restaurants, element: <Restaurants /> },
              { path: paths.restaurantRaw, element: <RestaurantAddEdit /> },
              { path: paths.vibes, element: <Vibes /> },
              {
                path: '*',
                element: <Navigate to={paths.restaurants} />,
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to={paths.discover} />,
      },
    ],
  },
]);

export default router;
