import { useState } from 'react';

import { Box, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import api from '../api';
import AppButton from '../components/AppButton';
import CardScreen from '../components/CardScreen';
import Input from '../components/Input';
import paths from '../routing/paths';
import useNotification from '../state/useNotification';

const ForgotPassword = () => {
  const [, setNotification] = useNotification();
  const [email, setEmail] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setSubmitted(true);

    if (!email) {
      return;
    }

    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const response = await api.auth.triggerResetPassword({
      email: email.trim().toLowerCase(),
    });

    if (!response.success) {
      setNotification({ message: 'Failed to start reset password flow. Please try again later' });
      setIsSaving(false);
      return;
    }

    setIsSaving(false);
    setNotification({ message: 'A reset password email has been sent to you!' });
    setIsSuccess(true);
  };

  const handleBackClick = () => {
    if (isSuccess) {
      setIsSuccess(false);
      return;
    }

    navigate(paths.login);
  };

  const handleDoneClick = () => {
    navigate(paths.login);
  };

  if (isSuccess) {
    return (
      <CardScreen title="Check your email" showBack onBackClick={handleBackClick}>
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          We have sent you a link to reset your password!
        </Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          Not receiving the email?{' '}
          <Box component="span">
            <Link style={{ color: 'inherit', cursor: 'pointer' }} onClick={handleSubmit}>
              {isSaving ? 'Sending...' : 'Resend'}
            </Link>
          </Box>
        </Typography>
        <AppButton onClick={handleDoneClick}>Done</AppButton>
      </CardScreen>
    );
  }

  return (
    <CardScreen title="Forgot password" showBack onBackClick={handleBackClick}>
      <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
        Enter your email address and we will send you a link to reset your password.
      </Typography>
      <Box
        component="form"
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '100%',
        }}
        onSubmit={handleSubmit}
      >
        <Input
          label="Email"
          name="email"
          value={email}
          onChange={setEmail}
          error="required"
          isError={submitted && !email}
        />
        <Box mb={2}>
          <AppButton isLoading={isSaving} type="submit">
            Send link
          </AppButton>
        </Box>
      </Box>
    </CardScreen>
  );
};

export default ForgotPassword;
