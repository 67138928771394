import { Box, Button, CircularProgress } from '@mui/material';

interface AppButtonProps {
  children: React.ReactNode;
  type?: 'button' | 'submit';
  color?: 'primary' | 'secondary' | 'accent' | 'text' | 'error';
  isLoading?: boolean;
  isFullWidth?: boolean;
  blockEventPropagation?: boolean;
  onClick?(): void;
}

const getButtonColor = (color: string): 'primary' | 'secondary' | 'error' => {
  if (color === 'accent') {
    return 'secondary';
  }
  if (color === 'error') {
    return 'error';
  }
  if (color === 'secondary') {
    return 'secondary';
  }
  return 'primary';
};

const AppButton = ({
  type,
  children,
  color = 'primary',
  isLoading,
  isFullWidth,
  blockEventPropagation,
  onClick,
}: AppButtonProps) => (
  <Button
    type={type || 'button'}
    variant={
      color === 'primary' || color === 'accent' || color === 'secondary' ? 'contained' : 'text'
    }
    color={getButtonColor(color)}
    disableElevation
    onClick={(e) => {
      if (blockEventPropagation) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (onClick) {
        onClick();
      }
    }}
    sx={{
      transition: 'width 100ms ease-in-out',
      minWidth: isLoading ? 0 : 160,
      width: isLoading ? 48 : isFullWidth ? '100%' : undefined,
      minHeight: 48,
      ...(color === 'accent' || color === 'secondary' ? { color: 'common.white' } : {}),
    }}
  >
    <Box display="flex" justifyContent="center" alignItems="center">
      {isLoading ? (
        <CircularProgress
          thickness={5}
          size={28}
          sx={{
            color: color === 'primary' || color === 'accent' ? 'common.white' : 'primary.main',
          }}
        />
      ) : (
        <>{children}</>
      )}
    </Box>
  </Button>
);

export default AppButton;
