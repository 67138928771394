import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
  CircularProgress,
  Box,
  IconButton,
  Icon,
  Typography,
  useTheme,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
} from '@mui/material';

import api from '../../api';
import useNotification from '../../state/useNotification';
import AppButton from '../../components/AppButton';
import Input from '../../components/Input';
import FileInput from '../../components/FileInput';
import { Cuisine, Image, Quote } from '../../models/restaurant';
import RatingStars from '../../components/RatingStars';
import AddressInput, { AddressInputResult } from '../../components/AddressInput';
import BottomSheet from '../../components/BottomSheet';
import PricePoints from '../../components/PricePoints';

const RestaurantAddEdit = () => {
  const theme = useTheme();
  const [submitted, setSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [rating, setRating] = useState(0);
  const [michelinStars, setMichelinStars] = useState(0);
  const [addressFull, setAddressFull] = useState('');
  const [addressShort, setAddressShort] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [addressRawJson, setAddressRawJson] = useState('');
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [bookingUrl, setBookingUrl] = useState('');
  const [pricePoint, setPricePoint] = useState<number>();
  const [searchable, setSearchable] = useState(false);
  const [, setNotification] = useNotification();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [fileUrl, setFileUrl] = useState('');
  const [file, setFile] = useState<File>();
  const [cuisineOptions, setCuisineOptions] = useState<Cuisine[]>([]);
  const [cuisines, setCuisines] = useState<string[]>([]);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [showQuotePopup, setShowQuotePopup] = useState(false);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [popupImageUrl, setPopupImageUrl] = useState('');
  const [popupImageFile, setPopupImageFile] = useState<File>();
  const [quoteText, setQuoteText] = useState('');
  const [quoteAuthor, setQuoteAuthor] = useState('');
  const [images, setImages] = useState<Image[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    api.cuisine.getAll().then((items) => {
      setCuisineOptions(items);
    });
  }, []);

  useEffect(() => {
    if (!file) {
      return;
    }

    setFileUrl(URL.createObjectURL(file));
  }, [file]);

  useEffect(() => {
    if (!popupImageFile) {
      return;
    }

    setPopupImageUrl(URL.createObjectURL(popupImageFile));
  }, [popupImageFile]);

  useEffect(() => {
    if (!id || id === 'new') {
      return;
    }

    setIsLoading(true);
    api.restaurant.getById(id).then((entity) => {
      setTitle(entity.title);
      setDescription(entity.description);
      setIsLoading(false);
      setFileUrl(entity.imageUrl || '');
      setRating(entity.rating || 0);
      setWebsiteUrl(entity.websiteUrl || '');
      setBookingUrl(entity.bookingUrl || '');
      setSearchable(entity.searchable || false);
      setMichelinStars(entity.michelinStars || 0);
      setAddressFull(entity.addressFull || '');
      setAddressShort(entity.addressShort || '');
      setAddressRawJson(entity.addressMetadata);
      setCity(entity.city || '');
      setCountry(entity.country || '');
      setLatitude(entity.latitude);
      setLongitude(entity.longitude);
      setCuisines(entity.cuisines.map(({ id }) => id || ''));
      setQuotes(entity.quotes);
      setImages(entity.images);
      setPricePoint(entity.pricePoint);
    });
  }, [id]);

  const handleAddressChange = (addressInfo: AddressInputResult) => {
    setAddressFull(addressInfo.address);
    setAddressShort(addressInfo.address);
    setAddressRawJson(addressInfo.jsonMetadata);
    setCity(addressInfo.city);
    setCountry(addressInfo.country);
    setLatitude(addressInfo.latitude);
    setLongitude(addressInfo.longitude);
  };

  const handleSubmit = async () => {
    setSubmitted(true);

    if (isSaving) {
      return;
    }

    if (!title || !description) {
      return;
    }

    setIsSaving(true);

    if (id === 'new') {
      await api.restaurant.create(
        {
          title,
          description,
          rating,
          michelinStars,
          addressFull,
          addressShort,
          addressMetadata: addressRawJson,
          city,
          country,
          latitude,
          longitude,
          websiteUrl,
          bookingUrl,
          pricePoint,
          searchable,
          cuisines: cuisineOptions.filter((item) => cuisines.includes(item.id || '')),
          images: [],
          quotes: [],
        },
        file,
      );

      setIsSaving(false);
      setNotification({ message: 'Restaurant added' });
      navigate(-1);
      return;
    }

    await api.restaurant.update(
      {
        id,
        title,
        description,
        rating,
        michelinStars,
        addressFull,
        addressShort,
        addressMetadata: addressRawJson,
        city,
        country,
        latitude,
        longitude,
        websiteUrl,
        bookingUrl,
        pricePoint,
        searchable,
        cuisines: cuisineOptions.filter((item) => cuisines.includes(item.id || '')),
        images: [],
        quotes: [],
      },
      file,
    );

    setIsSaving(false);
    setNotification({ message: 'Saved' });
    navigate(-1);
  };

  const handleAddQuote = () => {
    setQuoteText('');
    setQuoteAuthor('');
    setShowQuotePopup(true);
  };

  const handleAddQuoteSubmit = async () => {
    if (!quoteText) {
      return;
    }

    const response = await api.restaurant.addQuote(id || '', {
      id: '',
      text: quoteText,
      author: quoteAuthor,
    });

    setShowQuotePopup(false);
    setQuotes((prev) => [...prev, { id: response.id, text: quoteText, author: quoteAuthor }]);
  };

  const handleDeleteQuote = async (id: string) => {
    await api.restaurant.deleteQuote(id || '');
    setQuotes((prev) => prev.filter((item) => item.id !== id));
  };

  const handleAddImageClick = async () => {
    setPopupImageFile(undefined);
    setPopupImageUrl('');
    setShowImagePopup(true);
  };

  const handleAddImageSubmit = async () => {
    if (!popupImageFile) {
      return;
    }
    setIsUploading(true);
    const response = await api.restaurant.addImage(id || '', popupImageFile);
    setShowImagePopup(false);
    setImages((prev) => [...prev, { id: response.id, url: popupImageUrl }]);
    setIsUploading(false);
  };

  const handleDeleteImageClick = async (id: string) => {
    await api.restaurant.deleteImage(id || '');
    setImages((prev) => prev.filter((item) => item.id !== id));
  };

  return (
    <Box>
      <IconButton onClick={() => navigate(-1)}>
        <Icon>arrow_back</Icon>
      </IconButton>
      <Box sx={{ mt: 2, maxWidth: 500, mx: 'auto' }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Box
              component="form"
              noValidate
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
              }}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Typography mt={-2} variant="h6">
                {title}
              </Typography>
              <Typography variant="body1" color={theme.palette.grey[500]}>
                Restaurant details
              </Typography>
              <Box sx={{ mb: 1 }}>
                <RatingStars value={rating} onChange={setRating} />
              </Box>
              <Box sx={{ mb: 2 }}>
                <PricePoints values={pricePoint ? [pricePoint] : []} onSelect={setPricePoint} />
              </Box>
              <Input
                label="Title"
                name="resTitle"
                value={title}
                onChange={setTitle}
                error="required"
                isError={submitted && !title}
              />
              <Input
                label="Description"
                name="resDescription"
                value={description}
                onChange={setDescription}
                multiline
                error="required"
                isError={submitted && !description}
              />
              <Input
                label="Michelin stars"
                name="mischelin"
                value={michelinStars}
                onChange={setMichelinStars}
                error="required"
                isError={submitted && !description}
              />
              <Input
                label="Website link"
                name="website"
                placeholder="https://www.restaurant.com"
                value={websiteUrl}
                onChange={setWebsiteUrl}
              />
              <Input
                label="Booking link"
                name="booking link"
                placeholder="https://www.restaurant.com/reserve"
                value={bookingUrl}
                onChange={setBookingUrl}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Vibes</InputLabel>
                <Select
                  multiple
                  value={cuisines}
                  input={<OutlinedInput label="Cuisines" />}
                  onChange={(e) => setCuisines(e.target.value as any)}
                >
                  {cuisineOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ width: '100%', mb: 1 }}>
                <AddressInput onChange={handleAddressChange} />
                {addressFull && (
                  <Typography
                    variant="caption"
                    sx={{ ml: 1, mt: 1, color: theme.palette.grey[500] }}
                  >
                    Current: {addressFull}
                  </Typography>
                )}
              </Box>
              <FormControlLabel
                sx={{ mb: 1 }}
                control={
                  <Checkbox
                    checked={searchable}
                    onChange={(e) => setSearchable(e.target.checked)}
                    color="primary"
                  />
                }
                label="Searchable"
              />
              <FileInput label="Display image" fileUrl={fileUrl} onChange={setFile} />
              <Box mt={1} mb={2}>
                <AppButton type="submit" isLoading={isSaving}>
                  Save
                </AppButton>
              </Box>
            </Box>
            {id !== 'new' && (
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    pt: 2,
                  }}
                >
                  <Typography variant="body1">Restaurant images</Typography>
                  <Button onClick={handleAddImageClick}>Add image +</Button>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    gap: 2,
                    mt: 2,
                  }}
                >
                  {images.map((item) => (
                    <Box
                      key={item.id}
                      sx={{
                        width: 96,
                        height: 96,
                        borderRadius: 1,
                        position: 'relative',
                        overflow: 'hidden',
                        background: 'rgba(0,0,0,0.1)',
                      }}
                    >
                      <Box
                        component="img"
                        sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        src={item.url}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          height: '100%',
                          width: '100%',
                          top: 0,
                          left: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          opacity: 0,
                          transition: 'opacity 200ms ease-in-out',
                          background: 'rgba(0, 0, 0, 0.3)',
                          '&:hover': {
                            opacity: 1,
                          },
                        }}
                        onClick={() => handleDeleteImageClick(item.id)}
                      >
                        <Icon sx={{ color: theme.palette.common.white }}>delete</Icon>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    pt: 2,
                    mt: 2,
                  }}
                >
                  <Typography variant="body1">Restaurant quotes</Typography>
                  <Button onClick={handleAddQuote}>Add quote +</Button>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    pb: 2,
                    mb: 1,
                  }}
                >
                  {quotes.map((item) => (
                    <Box
                      key={item.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ color: theme.palette.grey[600], fontWeight: 400 }}
                      >
                        "{item.text}" {item.author && `- ${item.author}`}
                      </Typography>
                      <Button color="error" onClick={() => handleDeleteQuote(item.id)}>
                        Delete
                      </Button>
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </>
        )}
      </Box>

      <BottomSheet isOpen={showQuotePopup} onClose={() => setShowQuotePopup(false)}>
        <Box
          component="form"
          noValidate
          sx={{ m: 2 }}
          onSubmit={(e) => {
            e.preventDefault();
            handleAddQuoteSubmit();
          }}
        >
          <Typography variant="h6" mb={2}>
            Add Quote
          </Typography>
          <Input label="Quote" value={quoteText} onChange={setQuoteText} />
          <Input label="Author (optional)" value={quoteAuthor} onChange={setQuoteAuthor} />
          <AppButton type="submit">Save</AppButton>
        </Box>
      </BottomSheet>

      <BottomSheet isOpen={showImagePopup} onClose={() => setShowImagePopup(false)}>
        <Box sx={{ m: 2 }}>
          <Typography variant="h6" mb={2}>
            Add image
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <FileInput label="" fileUrl={popupImageUrl} onChange={setPopupImageFile} />
            <AppButton onClick={handleAddImageSubmit} isLoading={isUploading}>
              Upload
            </AppButton>
          </Box>
        </Box>
      </BottomSheet>
    </Box>
  );
};

export default RestaurantAddEdit;
