import React, { useRef } from 'react';
import { alpha, Box, Typography, useTheme, Icon } from '@mui/material';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile_filled.svg';
import { allowedFileTypes, allowedFileTypesDesktop } from '../../components/FileInput';
import useScreenWidth from '../../hooks/useScreenWidth';

interface ProfilePictureInputProps {
  fileUrl: string;
  label?: string;
  onChange(file: File): void;
}

const ProfilePictureInput = ({ label, fileUrl, onChange }: ProfilePictureInputProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const screenWidth = useScreenWidth();

  const fileTypes =
    screenWidth < theme.breakpoints.values.md ? allowedFileTypes : allowedFileTypesDesktop;

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      onChange(file);
    }
  };

  return (
    <Box
      mb={2}
      sx={{
        position: 'relative',
      }}
    >
      {label && (
        <Typography sx={{ marginBottom: 1 }} textAlign="center">
          {label}
        </Typography>
      )}
      <input
        type="file"
        accept={fileTypes.join(',')}
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {fileUrl ? (
        <Box
          component="img"
          sx={{
            width: 100,
            height: 100,
            background: alpha(theme.palette.primary.main, 0.1),
            objectFit: 'cover',
            borderRadius: '50%',
          }}
          src={fileUrl}
        />
      ) : (
        <Box
          sx={{
            background: alpha(theme.palette.primary.main, 0.05),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: 100,
            height: 100,
            borderRadius: '50%',
          }}
        >
          <ProfileIcon width={140} fill={theme.palette.primary.main} opacity={0.1} />
        </Box>
      )}
      <Box
        component="button"
        type="button"
        sx={{
          position: 'absolute',
          border: 'none',
          bottom: 0,
          right: 0,
          width: 28,
          height: 28,
          background: theme.palette.common.white,
          borderRadius: '50%',
          boxShadow: theme.shadows[2],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          padding: 2.5,
        }}
        onClick={handleButtonClick}
      >
        <Icon
          color="primary"
          sx={{
            fontSize: 22,
            height: 22,
            width: 22,
          }}
        >
          add_a_photo
        </Icon>
      </Box>
    </Box>
  );
};

export default ProfilePictureInput;
