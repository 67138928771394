import auth from './auth';
import user from './user';
import restaurant from './restaurant';
import cuisine from './cuisine';
import sentiment from './sentiment';
import preferences from './preferences';

const api = {
  auth,
  user,
  restaurant,
  cuisine,
  sentiment,
  preferences,
};

export default api;
