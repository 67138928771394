import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import api from '../../api';
import CardScreen from '../../components/CardScreen';
import AppButton from '../../components/AppButton';
import useNotification from '../../state/useNotification';
import Input from '../../components/Input';
import useUser from '../../state/useUser';
import ProfilePictureInput from './ProfilePictureInput';
import { HANDLE_MAX_LENGTH, HANDLE_MIN_LENGTH, HANDLE_REGEX } from '../../constants/regex';

const EditProfile = () => {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const [, setNotification] = useNotification();
  const [submitted, setSubmitted] = useState(false);
  const [user, setUser] = useUser();
  const [name, setName] = useState(user.firstName);
  const [handle, setHandle] = useState(user.handle);
  const [fileUrl, setFileUrl] = useState(user.profilePictureUrl);
  const [file, setFile] = useState<File>();

  useEffect(() => {
    if (!file) {
      return;
    }

    setFileUrl(URL.createObjectURL(file));
  }, [file]);

  const handleSubmit = async () => {
    setSubmitted(true);

    if (
      isSaving ||
      !name ||
      !handle ||
      handle.length < HANDLE_MIN_LENGTH ||
      handle.length > HANDLE_MAX_LENGTH
    ) {
      return;
    }

    setIsSaving(true);

    const response = await api.user.updateMe(
      {
        firstName: name,
        lastName: '',
        handle,
      },
      file,
    );

    if (!response.success) {
      setNotification({ message: 'Failed to save update' });
      setIsSaving(false);
      return;
    }

    const user = await api.user.getMe();
    setUser(user);
    setIsSaving(false);
    setNotification({ message: 'Profile updated' });
    navigate(-1);
  };

  const handleHandleChange = (value: string) => {
    const update = value.toLowerCase();

    if (!HANDLE_REGEX.test(update)) {
      return;
    }

    setHandle(update);
  };

  return (
    <CardScreen title="Edit profile" showBack onBackClick={() => navigate(-1)}>
      <Box
        component="form"
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '100%',
        }}
        onSubmit={(e: any) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <ProfilePictureInput fileUrl={fileUrl} onChange={setFile} />
        <Input
          label="Username"
          error="required"
          name="handle"
          value={handle}
          onChange={handleHandleChange}
          hint="Only lowercase letters, numbers, _ or ."
          isError={
            submitted &&
            (!handle || handle.length < HANDLE_MIN_LENGTH || handle.length > HANDLE_MAX_LENGTH)
          }
        />
        <Input
          label="Full name"
          error="required"
          name="name"
          value={name}
          onChange={setName}
          isError={submitted && !name}
        />
        <AppButton type="submit" isLoading={isSaving} isFullWidth>
          Save
        </AppButton>
      </Box>
    </CardScreen>
  );
};

export default EditProfile;
