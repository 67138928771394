import { Box, Typography, useTheme } from '@mui/material';
import { UserSearchResult } from '../../models/user';
import ProfilePictureIcon from '../../components/ProfilePictureIcon';

interface UserCardProps {
  user: UserSearchResult;
  actions?: React.ReactNode;
}

const UserCard = ({ user, actions }: UserCardProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
        px: 0,
        py: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <ProfilePictureIcon
          imageUrl={user.profilePictureUrl}
          name={user.firstName}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography variant="subtitle1" mb={-0.5}>
            {user.firstName}
          </Typography>
          <Typography variant="caption" sx={{ color: theme.palette.grey[500], mt: 0 }}>
            {user.handle}
          </Typography>
        </Box>
      </Box>
      {actions}
    </Box>
  );
};

export default UserCard;
