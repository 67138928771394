import React from 'react';
import { Box, Paper, Slide, useTheme } from '@mui/material';

interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  fullScreen?: boolean;
  maxWidth?: number | string;
  children: React.ReactNode;
}

const BottomSheet = ({ isOpen, fullScreen, onClose, children, maxWidth }: BottomSheetProps) => {
  const theme = useTheme();
  return (
    <>
      {isOpen && (
        <Box
          onClick={onClose}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 998,
            margin: 'auto',
            background: 'rgba(0, 0, 0, 0.3)',
          }}
        />
      )}
      <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 999,
            maxWidth: maxWidth || 600,
            maxHeight: fullScreen ? '100vh' : 'calc(100vh - 56px)',
            top: fullScreen ? 0 : 'unset',
            borderRadius: 0,
            borderTopLeftRadius: fullScreen ? 0 : 20,
            borderTopRightRadius: fullScreen ? 0 : 20,
            boxShadow: theme.shadows[3],
            margin: 'auto',
            overflowY: 'auto',

            '::-webkit-scrollbar': {
              width: '6px',
              height: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.grey[300],
              borderRadius: '4px',
            },

            '&::-webkit-scrollbar-track': {
              backgroundColor: theme.palette.grey[100],
              background: 'transparent',
            },
          }}
        >
          {children}
        </Paper>
      </Slide>
    </>
  );
};

export default BottomSheet;
