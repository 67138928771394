import { useState } from 'react';

import { Box, Link, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import api from '../api';
import AppButton from '../components/AppButton';
import CardScreen from '../components/CardScreen';
import paths from '../routing/paths';
import useNotification from '../state/useNotification';
import useUser from '../state/useUser';

const ConfirmEmailTrigger = () => {
  const [, setUser] = useUser();
  const [, setNotification] = useNotification();
  const search = useLocation().search;
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(new URLSearchParams(search).get('success') === 'true');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const response = await api.auth.triggerConfirmEmail();

    if (!response.success) {
      setNotification({ message: 'Failed to send confirmation email. Please try again later' });
      setIsSaving(false);
      return;
    }

    setIsSaving(false);
    setNotification({ message: 'A confirmation email has been sent to you!' });
    setIsSuccess(true);
  };

  const handleBackClick = () => {
    if (isSuccess) {
      setIsSuccess(false);
      return;
    }

    navigate(paths.login);
  };

  const handleDoneClick = async () => {
    const user = await api.user.getMe();
    setUser(user);
    navigate(paths.login);
  };

  if (isSuccess) {
    return (
      <CardScreen
        title="Check your email"
        showBack={new URLSearchParams(search).get('success') !== 'true'}
        onBackClick={handleBackClick}
      >
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          We have sent you a link to verifiy your email!
        </Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          Not receiving the email?{' '}
          <Box component="span">
            <Link style={{ color: 'inherit', cursor: 'pointer' }} onClick={handleSubmit}>
              {isSaving ? 'Sending...' : 'Resend'}
            </Link>
          </Box>
        </Typography>
        <AppButton isLoading={isSaving} onClick={handleDoneClick}>
          Done
        </AppButton>
      </CardScreen>
    );
  }

  return (
    <CardScreen title="Confirm email" showBack onBackClick={handleBackClick}>
      <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
        When you are ready, click the button below to receive a link to verify your
        email
      </Typography>
      <AppButton isLoading={isSaving} onClick={handleSubmit}>
        Send link
      </AppButton>
    </CardScreen>
  );
};

export default ConfirmEmailTrigger;
