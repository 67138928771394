import {
  Box,
  Button,
  CircularProgress,
  Icon,
  Link,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CardScreen from '../../components/CardScreen';
import useUser from '../../state/useUser';
import Input from '../../components/Input';
import api from '../../api';
import { UserSearchResult } from '../../models/user';
import BottomSheet from '../../components/BottomSheet';
import AppButton from '../../components/AppButton';
import UserCard from './UserCard';
import { SentimentOverlap } from '../../models/sentiment';
import RatingStars from '../../components/RatingStars';

enum ScreenType {
  CreateGroup,
  GroupInterest,
}

const parseUrl = (url: string) => {
  if (url.startsWith('https://') || url.startsWith('http://')) {
    return url;
  }
  return `https://${url}`;
};

const Group = () => {
  const [user] = useUser();
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [following, setFollowing] = useState<UserSearchResult[]>([]);
  const [filteredFollowing, setFilteredFollowing] = useState<UserSearchResult[]>([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const [groupUsers, setGroupUsers] = useState<{ [id: string]: UserSearchResult }>({
    [user.id]: user,
  });
  const [screenType, setScreenType] = useState(ScreenType.CreateGroup);
  const [overlapResults, setOverlapResults] = useState<SentimentOverlap[]>([]);
  const [isLoadingOverlap, setIsLoadingOverlap] = useState(false);
  const [selectedSentimentOverlap, setSelectedSentimentOverlap] = useState<SentimentOverlap>();

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      const lowerCaseSearch = search?.toLowerCase() || '';
      setFilteredFollowing(
        following
          .filter(
            (item) =>
              item.firstName?.toLowerCase().includes(lowerCaseSearch) ||
              item.lastName?.toLowerCase().includes(lowerCaseSearch) ||
              item.handle?.toLowerCase().includes(lowerCaseSearch),
          )
          .filter((item) => !groupUsers[item.id]),
      );
    }, 300);

    return () => clearTimeout(typingTimeout);
  }, [search, following, groupUsers]);

  useEffect(() => {
    setIsLoading(true);

    api.user.getFollowing().then((response) => {
      setFollowing(response);
      setIsLoading(false);
    });
  }, []);

  const handleAddUser = (user: UserSearchResult) => {
    setSearch('');
    setGroupUsers((prev) => ({
      ...prev,
      [user.id]: user,
    }));
  };

  const handleRemoveUser = (user: UserSearchResult) => {
    setGroupUsers((prev) => {
      const name = user.id;
      const { [name]: removedProperty, ...rest } = prev;
      return rest;
    });
  };

  const handleInterestOverlapClick = async () => {
    setScreenType(ScreenType.GroupInterest);
    setIsLoadingOverlap(true);
    const response = await api.sentiment.findOverlap(
      Object.keys(groupUsers)
        .map((key) => groupUsers[key])
        .map((item) => item.id),
    );
    setOverlapResults(response);
    setIsLoadingOverlap(false);
  };

  if (screenType === ScreenType.GroupInterest) {
    return (
      <CardScreen
        showBack
        onBackClick={() => {
          setScreenType(ScreenType.CreateGroup);
          setOverlapResults([]);
        }}
      >
        <Typography variant="h6" fontWeight={600} mb={3}>
          Overlapping interests
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyItems: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            p: 2,
            background: alpha(theme.palette.primary.light, 0.15),
            borderRadius: 1.5,
            mb: 3,
            gap: 1.5,
          }}
        >
          {Object.keys(groupUsers)
            .map((key) => groupUsers[key])
            .map((item) => (
              <Box
                key={item.handle}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: theme.palette.common.white,
                  borderRadius: 8,
                  px: 1,
                  py: 0,
                  boxShadow: theme.shadows[1],
                }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                  {user.handle === item.handle ? 'Me' : item.handle}
                </Typography>
              </Box>
            ))}
        </Box>
        {isLoadingOverlap && (
          <Box mb={2}>
            <CircularProgress size={32} />
          </Box>
        )}

        {!overlapResults.length && !isLoadingOverlap && (
          <Box mt={3} mb={3}>
            <Typography sx={{ fontSize: 14 }} color={theme.palette.grey[500]}>
              No overlapping interest found
            </Typography>
          </Box>
        )}

        <Box>
          {overlapResults.map((item) => (
            <React.Fragment key={item.restaurant.id}>
              <Box
                sx={{
                  background: theme.palette.success.main,
                  color: theme.palette.common.white,
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: 1,
                  px: 2,
                  py: 1,
                  mb: 2,
                }}
              >
                <Typography
                  variant="body1"
                  color="inherit"
                  sx={{ fontSize: 12, fontWeight: 500, display: 'flex', alignItems: 'center' }}
                >
                  {item.userIds.length === Object.keys(groupUsers).length
                    ? 'All '
                    : `${item.userIds.length}/${Object.keys(groupUsers).length} `}
                  interested
                  {Boolean(item.unInterestedUserIds.length) &&
                    ` • ${item.unInterestedUserIds.length} not interested`}
                  {item.userIds.length === Object.keys(groupUsers).length && (
                    <Icon sx={{ fontSize: 20, ml: 1 }}>check</Icon>
                  )}
                </Typography>
                <Box onClick={() => setSelectedSentimentOverlap(item)} sx={{ cursor: 'pointer' }}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    sx={{ fontSize: 12, textDecoration: 'underline', fontWeight: 500 }}
                  >
                    See who
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mb: 2,
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  pb: 2,
                }}
              >
                <Typography
                  variant="body1"
                  textAlign="left"
                  sx={{ fontSize: 16, fontWeight: 500, ml: 1 }}
                >
                  {item.restaurant.title}
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="left"
                  sx={{
                    fontSize: 12,
                    ml: 1,
                    display: 'flex',
                    color: theme.palette.grey[500],
                  }}
                >
                  {item.restaurant.description}
                </Typography>
              </Box>
            </React.Fragment>
          ))}
          <AppButton onClick={() => navigate(-1)}>Done</AppButton>
        </Box>
        <BottomSheet
          isOpen={!!selectedSentimentOverlap}
          onClose={() => setSelectedSentimentOverlap(undefined)}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="h6">{selectedSentimentOverlap?.restaurant.title}</Typography>
            <RatingStars value={selectedSentimentOverlap?.restaurant.rating || 0} />
            {selectedSentimentOverlap?.userIds
              .map((id) => groupUsers[id])
              .map((item) => (
                <UserCard
                  key={item.id}
                  user={item}
                  actions={
                    <Box>
                      <Icon sx={{ color: theme.palette.success.main }}>favorite</Icon>
                    </Box>
                  }
                />
              ))}
            {selectedSentimentOverlap?.unInterestedUserIds
              .map((id) => groupUsers[id])
              .map((item) => (
                <UserCard
                  key={item.id}
                  user={item}
                  actions={
                    <Box>
                      <Icon sx={{ color: theme.palette.error.main }}>close</Icon>
                    </Box>
                  }
                />
              ))}
            {(selectedSentimentOverlap?.restaurant.websiteUrl ||
              selectedSentimentOverlap?.restaurant.bookingUrl) && (
              <Link
                href={parseUrl(
                  selectedSentimentOverlap.restaurant.bookingUrl ||
                    selectedSentimentOverlap.restaurant.websiteUrl ||
                    '',
                )}
                target="_blank"
              >
                <AppButton>
                  Make a reservation <Icon sx={{ ml: 2 }}>open_in_new</Icon>
                </AppButton>
              </Link>
            )}
          </Box>
        </BottomSheet>
      </CardScreen>
    );
  }

  return (
    <CardScreen showBack onBackClick={() => navigate(-1)}>
      <Typography variant="h6" fontWeight={600} mb={3}>
        Choose people
      </Typography>
      {Boolean(Object.keys(groupUsers).length > 1) && (
        <Box
          sx={{
            display: 'flex',
            justifyItems: 'flex-start',
            flexWrap: 'wrap',
            alignItems: 'center',
            p: 2,
            background: alpha(theme.palette.primary.light, 0.15),
            borderRadius: 1.5,
            mb: 2,
            gap: 1.5,
          }}
        >
          {Object.keys(groupUsers)
            .map((key) => groupUsers[key])
            .map((item) => (
              <Box
                key={item.handle}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: theme.palette.common.white,
                  borderRadius: 8,
                  boxShadow: theme.shadows[1],
                  px: 1,
                  py: 0,
                }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                  {user.handle === item.handle ? 'Me' : item.handle}
                </Typography>
                {item.id !== user.id && (
                  <Box
                    onClick={() => handleRemoveUser(item)}
                    sx={{ cursor: 'pointer', display: 'contents' }}
                  >
                    <Icon sx={{ ml: 1, fontSize: 16 }}>close</Icon>
                  </Box>
                )}
              </Box>
            ))}
        </Box>
      )}
      {Boolean(Object.keys(groupUsers).length > 1) && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            variant="contained"
            disableElevation
            sx={{ height: 32 }}
            onClick={handleInterestOverlapClick}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 500 }} color="inherit">
              See interests
            </Typography>
          </Button>
        </Box>
      )}
      <Input label="Search people" value={search} onChange={setSearch} isClearable />
      <Typography
        variant="caption"
        sx={{
          mt: -1,
          mb: 2,
          ml: 1,
          textAlign: 'left',
          color: theme.palette.grey[500],
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <Icon sx={{ fontSize: 20 }}>info</Icon>
        You can only add people that you are following
      </Typography>
      {isLoading && (
        <Box mb={2}>
          <CircularProgress size={32} />
        </Box>
      )}
      {!filteredFollowing.length && search && (
        <Box mt={3}>
          <Typography sx={{ fontSize: 14 }} color={theme.palette.grey[500]}>
            No results found
          </Typography>
        </Box>
      )}
      {filteredFollowing.map((user) => (
        <UserCard
          key={user.id}
          user={user}
          actions={
            <Button
              variant="contained"
              disableElevation
              sx={{ height: 32 }}
              onClick={() => handleAddUser(user)}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  cursor: 'pointer',
                }}
                color="inherit"
              >
                Add +
              </Typography>
            </Button>
          }
        />
      ))}
    </CardScreen>
  );
};

export default Group;
