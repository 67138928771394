import { Box, useTheme } from '@mui/material';
import { pricePointOptions } from '../constants/pricePoints';
import { CurrencySymbol } from '../constants/currencies';

interface PricePointsProps {
  values: number[];
  onSelect?(value: number): void;
}

const PricePoints: React.FC<PricePointsProps> = ({ values, onSelect }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 1,
        mb: 0,
      }}
    >
      {pricePointOptions.map((option) => (
        <Box
          key={option}
          sx={{
            borderRadius: 4,
            border: `1px solid ${
              values.includes(option) ? theme.palette.primary.main : theme.palette.grey[300]
            }`,
            px: 2,
            py: 0.1,
            cursor: 'pointer',
            background: values.includes(option)
              ? theme.palette.primary.main
              : theme.palette.common.white,
            color: values.includes(option)
              ? theme.palette.common.white
              : theme.palette.primary.main,
          }}
          onClick={() => onSelect && onSelect(option)}
        >
          {CurrencySymbol.Gbp.repeat(option)}
        </Box>
      ))}
    </Box>
  );
};

export default PricePoints;
