import { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import AppButton from '../components/AppButton';
import CardScreen from '../components/CardScreen';
import Input from '../components/Input';
import paths from '../routing/paths';
import useNotification from '../state/useNotification';
import api from '../api';

const ResetPassword = () => {
  const [, setNotification] = useNotification();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setSubmitted(true);

    if (!confirmPassword || !password) {
      return;
    }

    if (password !== confirmPassword) {
      setNotification({ message: 'Password confirmation does not match password' });
      return;
    }

    if (!token) {
      setNotification({ message: 'Invalid token' });
      return;
    }
    
    setIsSaving(true);

    const response = await api.auth.resetPassword({
      token,
      password,
    });
    
    setIsSaving(false);

    if (!response.success) {
      setNotification({ message: 'Failed to reset password. Please try again later' });
      return;
    }

    setNotification({ message: 'Password has been successfully reset' });
    setIsSuccess(true);
  };

  const handleDoneClick = () => {
    navigate(paths.login);
  }

  if (isSuccess) {
    return (
      <CardScreen title="Success!">
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          Your password has been reset
        </Typography>
        <AppButton onClick={handleDoneClick}>
          Try it out
        </AppButton>
      </CardScreen>
    );
  }

  return (
    <CardScreen title="Reset password">
      <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
        Enter your new password
      </Typography>
      <Box
        component="form"
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '100%',
        }}
        onSubmit={handleSubmit}
      >
        <Input
          label="New password"
          name="newpassword"
          value={password}
          onChange={setPassword}
          error="required"
          isError={submitted && !password}
          type="password"
        />
        <Input
          label="Confirm password"
          name="confirmpassword"
          value={confirmPassword}
          onChange={setConfirmPassword}
          error="required"
          isError={submitted && !confirmPassword}
          type="password"
        />
        <AppButton isLoading={isSaving} type="submit">
          Reset password
        </AppButton>
      </Box>
    </CardScreen>
  );
};

export default ResetPassword;
