const pathPrefix = '/api';

const apiClient = {
  post: async (path: string, payload: object) => {
    const response = await fetch(`${pathPrefix}${path}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const data = response.json();

    return data;
  },
  postFormData: async (path: string, payload: FormData) => {
    const response = await fetch(`${pathPrefix}${path}`, {
      method: 'POST',
      credentials: 'same-origin',
      body: payload as any,
    });
    const data = response.json();

    return data;
  },
  get: async (path: string) => {
    const response = await fetch(`${pathPrefix}${path}`, {
      method: 'GET',
      credentials: 'same-origin',
    });
    const data = await response.json();

    return data;
  },
  patch: async (path: string, payload: object) => {
    const response = await fetch(`${pathPrefix}${path}`, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();

    return data;
  },
  put: async (path: string, payload: object) => {
    const response = await fetch(`${pathPrefix}${path}`, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();

    return data;
  },
  putFormData: async (path: string, payload: FormData) => {
    const response = await fetch(`${pathPrefix}${path}`, {
      method: 'PUT',
      credentials: 'same-origin',
      body: payload,
    });
    const data = await response.json();

    return data;
  },
  delete: async (path: string) => {
    const response = await fetch(`${pathPrefix}${path}`, {
      method: 'DELETE',
      credentials: 'same-origin',
    });

    return response.status === 200 || response.status === 202 || response.status === 204;
  },
};

export default apiClient;
