export interface Restaurant {
  id?: string;
  title: string;
  description: string;
  imageUrl?: string;
  rating?: number;
  michelinStars: number;
  addressFull?: string;
  addressShort?: string;
  addressMetadata?: any;
  city?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  websiteUrl?: string;
  bookingUrl?: string;
  pricePoint?: number;
  cuisines: Cuisine[];
  images: Image[];
  quotes: Quote[];
  searchable?: boolean;
}

export interface Cuisine {
  id?: string;
  title: string;
}

export interface Image {
  id: string;
  url: string;
}

export interface Quote {
  id: string;
  text: string;
  author: string;
}

export enum Sentiment {
  Unknown = '',
  KeenToGo = 'keen',
  NotInterested = 'not_interested',
}
