import { Snackbar } from '@mui/material';

import useNotification from '../../state/useNotification';

const NotificationDisplay = () => {
  const [notification, setNotification] = useNotification();

  return (
    <Snackbar
      autoHideDuration={3000}
      open={Boolean(notification?.message)}
      onClose={() => setNotification(undefined)}
      message={notification?.message}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    />
  );
};

export default NotificationDisplay;
