import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { GOOGLE_MAPS_API_KEY } from '../config';

export interface AddressInputResult {
  address: string;
  shortAddress: string;
  latitude: number;
  longitude: number;
  country: string;
  city: string;
  jsonMetadata: any;
}

interface AddressInputProps {
  placeholder?: string;
  label?: string;
  onChange: (addressDetails: AddressInputResult) => void;
}

const AddressInput: React.FC<AddressInputProps> = ({ label, placeholder, onChange }) => {
  const [options, setOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = async (event: any) => {
    setInputValue(event.target.value);
    const addressOptions = await fetchAddressOptions(event.target.value);
    setOptions(addressOptions);
  };

  const fetchAddressOptions = async (input: string) => {
    try {
      const response = await fetch(
        `/maps/api/place/autocomplete/json?input=${encodeURIComponent(
          input,
        )}&key=${GOOGLE_MAPS_API_KEY}`,
      );
      const data = await response.json();
      if (data.predictions) {
        return data.predictions.map((prediction: any) => prediction.description);
      }
      return [];
    } catch (error) {
      console.error('Error fetching address options:', error);
      return [];
    }
  };

  const handleSelectAddress = async (address: string) => {
    setInputValue(address);
    try {
      const response = await fetch(
        `/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`,
      );
      const data = await response.json();
      const { results } = data;
      if (results && results.length > 0) {
        const item: any = results[0];
        const addressComponents = item.address_components;
        const countryCode =
          addressComponents.find((item: any) => item.types.includes('country'))?.short_name || '';
        const city =
          addressComponents.find((item: any) => item.types.includes('locality'))?.short_name || '';
        const streetNumber = addressComponents.find((item: any) =>
          item.types.includes('street_number'),
        )?.long_name;
        const streetName = addressComponents.find((item: any) =>
          item.types.includes('route'),
        )?.long_name;
        const subLocality = addressComponents.find((item: any) =>
          item.types.includes('sublocality_level_1'),
        )?.long_name;
        onChange({
          address: item.formatted_address,
          shortAddress: `${streetNumber} ${streetName},${
            subLocality ? ` ${subLocality},` : ''
          } ${city}`,
          jsonMetadata: item,
          latitude: item.geometry.location.lat,
          longitude: item.geometry.location.lng,
          country: countryCode,
          city: city,
        });
      } else {
        console.error('No results found for the provided address.');
      }
    } catch (error) {
      console.error('Error fetching coordinates for address:', error);
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={options}
      value={inputValue}
      onChange={(_, value) => handleSelectAddress(value || '')}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          placeholder={placeholder}
          {...params}
          label={label || 'Select address'}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};

export default AddressInput;
