import { useContext } from 'react';

import { User } from '../models/user';
import { GlobalContext } from './GlobalContext';

const useUser: () => [User, (user: User) => void] = () => {
  const { user, setUser } = useContext(GlobalContext);
  return [user, setUser];
};

export default useUser;
