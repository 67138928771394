import React from 'react';
import AppButton from './AppButton';
import { Icon } from '@mui/material';

const DownloadTemplate: React.FC = () => {
  const downloadFile = async () => {
    try {
      const response = await fetch('/api/restaurants/bulk/template', {
        credentials: 'same-origin',
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'fatallan-template.xlsx';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  return (
    <AppButton onClick={downloadFile}>
      <Icon sx={{ mr: 1 }}>download</Icon>
      Template
    </AppButton>
  );
};

export default DownloadTemplate;
