import { Box, Divider, Typography, useTheme } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import MenuItem from '@mui/material/MenuItem';
import paths from '../../routing/paths';
import { Link } from 'react-router-dom';
import ProfilePictureIcon from '../ProfilePictureIcon';
import useUser from '../../state/useUser';
import { useContext } from 'react';
import { GlobalContext } from '../../state/GlobalContext';
import api from '../../api';

const menuItems: { title: string; url: string }[] = [
  {
    title: 'Restaurants',
    url: paths.restaurants,
  },
  {
    title: 'Vibes',
    url: paths.vibes,
  },
];

const AdminNav = ({ children }: { children: React.ReactNode }) => {
  const [user] = useUser();
  const theme = useTheme();
  const { logout } = useContext(GlobalContext);

  const handleLogout = async () => {
    await api.auth.logout();
    logout();
  };

  const menuHeight = 80;
  const sideMenuWidth = 200;
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `2px solid ${theme.palette.grey[200]}`,
          height: menuHeight,
          position: 'fixed',
          zIndex: 2,
          px: 2,
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          background: theme.palette.common.white,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 2 }}>
          <Link to={paths.home}>
            <Logo style={{ height: 64, width: 'auto', minWidth: 60 }} />
          </Link>
          <Typography variant="h6">Admin Portal</Typography>
        </Box>
        <ProfilePictureIcon name={user.firstName} imageUrl={user.profilePictureUrl} />
      </Box>
      <Box sx={{ marginLeft: `${sideMenuWidth + 16}px`, pt: `${menuHeight + 8}px` }}>
        {children}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          height: '100%',
          left: 0,
          top: menuHeight,
          background: theme.palette.grey[200],
          borderRight: `1px solid ${theme.palette.grey[200]}`,
          width: sideMenuWidth,
          pt: 2,
        }}
      >
        {menuItems.map((item) => (
          <Link to={item.url} key={item.title} style={{ textDecoration: 'none' }}>
            <MenuItem>
              <Typography sx={{ pl: 2 }}>{item.title}</Typography>
            </MenuItem>
          </Link>
        ))}
        <Divider sx={{ mt: 2 }} />
        <Box sx={{ mt: 2, cursor: 'pointer' }} onClick={handleLogout}>
          <MenuItem sx={{ pl: 4 }}>
            <Typography>Logout</Typography>
          </MenuItem>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminNav;
