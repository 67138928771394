import { useEffect, useState } from 'react';
import api from '../../api';
import { Restaurant } from '../../models/restaurant';
import { Box, Button, Icon, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import paths from '../../routing/paths';
import AppButton from '../../components/AppButton';
import useNotification from '../../state/useNotification';
import DownloadTemplateButton from '../../components/DownloadTemplateButton';
import BulkUploadModal from './BulkUploadModal';

const descriptionCutoffCharacters = 80;

const Restaurants = () => {
  const [items, setItems] = useState<Restaurant[]>([]);
  const theme = useTheme();
  const [, setNotification] = useNotification();

  useEffect(() => {
    api.restaurant.getAll().then((items) => {
      setItems(items);
    });
  }, []);

  const handleDelete = async (id: string) => {
    await api.restaurant.delete(id);
    setItems((items) => items.filter((item) => item.id !== id));
    setNotification({ message: 'Restaurant deleted' });
  };

  const handleRefreshItems = async () => {
    const items = await api.restaurant.getAll();
    setItems(items || []);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">Restaurants {!!items.length && `(${items.length})`}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mb: 2, gap: 2 }}>
        <BulkUploadModal afterBulkUploadSuccess={handleRefreshItems} />
        <DownloadTemplateButton />
        <Link to={paths.restaurantPathBuilder('')}>
          <AppButton>
            <Icon sx={{ ml: 0.5 }}>add</Icon> Add new
          </AppButton>
        </Link>
      </Box>
      <Box>
        {items.map((item) => (
          <Box
            key={item.id}
            sx={{
              p: 1,
              mb: 1,
              pb: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
              {item.imageUrl && (
                <Box
                  sx={{
                    objectFit: 'cover',
                    height: 60,
                    width: 60,
                  }}
                  component="img"
                  src={item.imageUrl}
                />
              )}
              <Box>
                <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
                  <Typography variant="body1" fontWeight={500}>
                    {item.title}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
                  <Typography variant="body2" color={theme.palette.grey[500]}>
                    {item.description?.slice(0, descriptionCutoffCharacters)}
                    {item.description?.length > descriptionCutoffCharacters && '...'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
              <Link to={paths.restaurantPathBuilder(item.id || '')}>
                <Button>Edit</Button>
              </Link>
              <Typography
                sx={{ cursor: 'pointer' }}
                color="error"
                onClick={() => handleDelete(item.id as string)}
              >
                <Button color="error">Delete</Button>
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Restaurants;
