import { useState, useEffect } from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import useUser from '../state/useUser';
import paths from '../routing/paths';
import useNotification from '../state/useNotification';
import AppButton from '../components/AppButton';
import api from '../api';
import CardScreen from '../components/CardScreen';
import Input from '../components/Input';
import withUnauthenticatedOnlyGuard from '../components/smart/hoc/withUnauthenticatedOnlyGuard';
import { HANDLE_MAX_LENGTH, HANDLE_MIN_LENGTH, HANDLE_REGEX } from '../constants/regex';
import { signupNames } from '../features';

const Signup = () => {
  const [user, setUser] = useUser();
  const [, setNotification] = useNotification();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [handle, setHandle] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const returnUrl = queryParams.get('returnUrl');

  useEffect(() => {
    if (user.id) {
      if (returnUrl) {
        navigate(returnUrl);
        return;
      }

      navigate({
        pathname: paths.emailConfirmTrigger,
        search: '?success=true',
      });
    }
  }, [user, navigate]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setSubmitted(true);

    if (signupNames && !name) {
      return;
    }

    if (!confirmPassword || !password || !email) {
      return;
    }

    if (!handle || handle.length < HANDLE_MIN_LENGTH || handle.length > HANDLE_MAX_LENGTH) {
      setNotification({
        message: `Username must between ${HANDLE_MIN_LENGTH} and ${HANDLE_MAX_LENGTH} characters long`,
      });
      return;
    }

    if (password !== confirmPassword) {
      setNotification({ message: 'Password confirmation does not match password' });
      return;
    }

    setIsSaving(true);

    const response = await api.auth.signup({
      firstName: name,
      lastName: '',
      handle,
      email: email.trim().toLowerCase(),
      password,
    });

    if (!response.success) {
      setNotification({ message: 'Unable to create account' });
      setIsSaving(false);
      return;
    }

    const [userResponse] = await Promise.all([api.user.getMe()]);
    setUser(userResponse);
  };

  const handleHandleChange = (value: string) => {
    const update = value.toLowerCase();

    if (!HANDLE_REGEX.test(update)) {
      return;
    }

    setHandle(update);
  };

  return (
    <CardScreen title="Signup">
      <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
        Already have an account?{' '}
        <Box component="span">
          <Link
            style={{ color: 'inherit', fontWeight: 600 }}
            to={`${paths.login}${location.search}`}
          >
            Login
          </Link>
        </Box>
      </Typography>
      <Box
        component="form"
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '100%',
        }}
        onSubmit={handleSubmit}
      >
        <Input
          label="Username"
          error="required"
          name="handle"
          value={handle}
          onChange={handleHandleChange}
          hint="Only lowercase letters, numbers, _ or ."
          isError={
            submitted &&
            (!handle || handle.length < HANDLE_MIN_LENGTH || handle.length > HANDLE_MAX_LENGTH)
          }
        />
        {signupNames && (
          <>
            <Input
              label="Full name"
              name="name"
              value={name}
              onChange={setName}
              error="required"
              isError={submitted && !name}
            />
          </>
        )}
        <Input
          label="Email"
          name="email"
          value={email}
          onChange={setEmail}
          error="required"
          isError={submitted && !email}
        />
        <Input
          label="Password"
          name="password"
          value={password}
          onChange={setPassword}
          error="required"
          isError={submitted && !password}
          type="password"
        />
        <Input
          label="Confirm password"
          name="confirmpassword"
          value={confirmPassword}
          onChange={setConfirmPassword}
          error="required"
          isError={submitted && !confirmPassword}
          type="password"
        />
        <Box mt={1} width={'100%'}>
          <AppButton isLoading={isSaving} isFullWidth type="submit">
            Create account
          </AppButton>
        </Box>
        <Box mt={2} maxWidth={350}>
          <Typography variant="caption">
            By creating an account, I consent to the{' '}
            <Box
              component="a"
              href=""
              target="_blank"
              fontWeight={600}
              color={theme.palette.primary.main}
            >
              privacy policy
            </Box>
          </Typography>
        </Box>
      </Box>
    </CardScreen>
  );
};

export default withUnauthenticatedOnlyGuard(Signup);
