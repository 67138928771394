import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { theme } from './theme';
import router from './routing/router';
import ContextProvider from './state/ContextProvider';
import NotificationDisplay from './components/smart/NotificationDisplay';
import AuthGate from './components/smart/AuthGate';

const App = () => {
  return (
    <ContextProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthGate>
          <RouterProvider router={router} />
        </AuthGate>
        <NotificationDisplay />
      </ThemeProvider>
    </ContextProvider>
  );
};

export default App;
