import { Cuisine } from './restaurant';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  handle: string;
  email: string;
  emailConfirmed: boolean;
  userType: UserType;
  profilePictureUrl: string;
}

export interface UserSearchResult {
  id: string;
  firstName: string;
  lastName: string;
  handle: string;
  profilePictureUrl: string;
}

export interface UpdateUserRequest {
  firstName: string;
  lastName: string;
  handle: string;
}

export enum UserType {
  Admin = 'admin',
  User = 'user',
}

export interface UserPreferences {
  pricePoints: number[];
  cuisines: Cuisine[];
  location?: {
    name: string;
    rawJson?: any;
    lat?: number;
    lng?: number;
  };
  michelinStars?: number;
}
