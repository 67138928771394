import { Quote, Restaurant, Sentiment } from '../models/restaurant';
import apiClient from './apiClient';

const prefix = '/restaurants';

const restaurant = {
  create: async (data: Restaurant, file?: File): Promise<string> => {
    const formData = new FormData();
    if (file) {
      formData.append('image', file);
    }
    formData.append('data', JSON.stringify(data));
    return await apiClient.postFormData(`${prefix}`, formData);
  },
  getAll: async (): Promise<Restaurant[]> => await apiClient.get(`${prefix}`),
  discover: async (): Promise<Restaurant[]> => await apiClient.get(`${prefix}/discover`),
  getBySentiment: async (sentiment: Sentiment): Promise<Restaurant[]> =>
    await apiClient.get(`${prefix}/by-sentiment/${sentiment}`),
  getById: async (id: string): Promise<Restaurant> => await apiClient.get(`${prefix}/${id}`),
  update: async (data: Restaurant, file?: File): Promise<string> => {
    const formData = new FormData();
    if (file) {
      formData.append('image', file);
    }
    formData.append('data', JSON.stringify(data));
    return await apiClient.putFormData(`${prefix}/${data.id}`, formData);
  },
  delete: async (id: string): Promise<boolean> => await apiClient.delete(`${prefix}/${id}`),
  addQuote: async (id: string, dto: Quote): Promise<{ id: string }> =>
    await apiClient.post(`${prefix}/quote/${id}`, dto),
  deleteQuote: async (id: string): Promise<boolean> =>
    await apiClient.delete(`${prefix}/quote/${id}`),
  addImage: async (id: string, file: File): Promise<{ id: string }> => {
    const formData = new FormData();
    if (file) {
      formData.append('image', file);
    }
    return await apiClient.postFormData(`${prefix}/image/${id}`, formData);
  },
  deleteImage: async (id: string): Promise<boolean> =>
    await apiClient.delete(`${prefix}/image/${id}`),
  bulkUpload: async (file: File): Promise<any> => {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    return await apiClient.postFormData(`${prefix}/bulk/upload`, formData);
  },
};

export default restaurant;
