import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, IconButton, useTheme } from '@mui/material';

interface RatingStarsProps {
  value: number;
  onChange?: (value: number) => void;
}

const RatingStars: React.FC<RatingStarsProps> = ({ value, onChange }) => {
  const theme = useTheme();
  const handleClick = (newValue: number) => {
    if (!onChange) {
      return;
    }
    onChange(newValue === value ? 0 : newValue);
  };

  return (
    <Box>
      {[1, 2, 3, 4, 5].map((index) => (
        <IconButton
          key={index}
          onClick={() => handleClick(index)}
          aria-label={`Star ${index}`}
          size="small"
          sx={{
            color: value >= index ? theme.palette.secondary.main : theme.palette.grey[400],
            ml: 0,
          }}
        >
          {value >= index ? <StarIcon /> : <StarBorderIcon />}
        </IconButton>
      ))}
    </Box>
  );
};

export default RatingStars;
