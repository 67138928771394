import { Box, Typography, useTheme } from '@mui/material';

interface ProfilePictureIconProps {
  imageUrl?: string;
  name: string;
  size?: number;
}

const getInitials = (name: string): string => {
  if (!name) {
    return '';
  }
  const names = name.split(' ');
  let initials = '';

  if (names.length === 1) {
    return names[0][0];
  } else {
    initials += names[0][0];
    initials += names[names.length - 1][0];
  }

  return initials;
};

const ProfilePictureIcon = ({ imageUrl, name, size }: ProfilePictureIconProps) => {
  const theme = useTheme();
  return (
    <>
      {imageUrl ? (
        <Box
          component="img"
          src={imageUrl}
          sx={{
            width: size || 40,
            height: size || 40,
            display: 'block',
            borderRadius: '50%',
            objectFit: 'cover',
            background: theme.palette.grey[200],
          }}
        />
      ) : (
        <Box
          sx={{
            width: size || 40,
            height: size || 40,
            borderRadius: '50%',
            objectFit: 'cover',
            background: theme.palette.grey[200],
            boxShadow: theme.shadows[1],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            {getInitials(name)}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ProfilePictureIcon;
