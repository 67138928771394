import React, { useEffect, useState } from 'react';

import api from '../../api';
import { User } from '../../models/user';
import useUser from '../../state/useUser';

const AuthGate = ({ children }: { children: React.ReactNode }) => {
  const [, setUser] = useUser();
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    Promise.all([api.user.getMe()])
      .then(async ([user]) => {
        setInitialised(true);
        if (!user.id) {
          await api.auth.logout();
          setUser({} as User);
          return;
        }
        setUser(user);
      })
      .catch(() => {
        setInitialised(true);
      });
  }, [setUser]);

  if (!initialised) {
    return <></>;
  }

  return <>{children}</>;
};

export default AuthGate;
