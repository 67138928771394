import { Box, Icon, useTheme } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from '../../routing/paths';

enum Tab {
  Unknown,
  Discover,
  People,
  Profile,
}

interface TabItem {
  value: Tab;
  icon: string;
}

const tabs: TabItem[] = [
  { value: Tab.Discover, icon: 'restaurant' },
  { value: Tab.People, icon: 'people_alt' },
  { value: Tab.Profile, icon: 'account_circle' },
];

const getTab = (path: string): Tab => {
  if (path.startsWith(paths.discover)) {
    return Tab.Discover;
  }
  if (path.startsWith(paths.people)) {
    return Tab.People;
  }
  if (path.startsWith(paths.profile)) {
    return Tab.Profile;
  }
  return Tab.Discover;
};

const validRoutes = [
  paths.lists,
  paths.profile,
  paths.profileEdit,
  paths.discoverPreferences,
  paths.people,
  paths.peopleGroup,
  paths.discover,
];

const NavBar = () => {
  const theme = useTheme();
  const location = useLocation();
  const [current, setCurrent] = useState(getTab(location.pathname));
  const navigate = useNavigate();

  const handleClick = (tab: Tab) => {
    setCurrent(tab);
    if (tab === Tab.Discover) {
      navigate(paths.discover);
    }
    if (tab === Tab.Profile) {
      navigate(paths.profile);
    }
    if (tab === Tab.People) {
      navigate(paths.people);
    }
  };

  if (!validRoutes.find((route) => route.startsWith(location.pathname))) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        maxWidth: 400,
        margin: 'auto',
        mt: 0,
        py: 0,
        px: 2,
        borderRadius: 8,
        boxShadow: '0px 0px 8px 0 rgba(0,0,0,0.2)',
        mb: 1,
        position: 'fixed',
        height: 40,
        top: 0,
        left: 0,
        right: 0,
        [theme.breakpoints.down('sm')]: {
          borderRadius: 0,
          width: '100%',
          maxWidth: '100%',
        },
      }}
    >
      {tabs.map((tab) => (
        <Box
          key={tab.value}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            color: tab.value === current ? theme.palette.secondary.main : theme.palette.grey[500],
            borderRadius: 100,
            p: 1.5,
            boxShadow: tab.value === current ? '0px 0px 8px 0 rgba(0,0,0,0.2)' : 'none',
            background: tab.value === current ? theme.palette.common.white : 'transparent',
            mt: tab.value === current ? 3 : 0,
            transition: 'margin-top 100ms ease-in-out',
            WebkitTapHighlightColor: 'transparent',
          }}
          onClick={() => handleClick(tab.value)}
        >
          <Icon sx={{ fontSize: 24 }}>{tab.icon}</Icon>
        </Box>
      ))}
    </Box>
  );
};

export default NavBar;
