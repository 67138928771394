import { createContext } from 'react';

import { User } from '../models/user';

export interface NotificationMessage {
  message: string;
}

interface GlobalState {
  user: User;
  notification?: NotificationMessage;
  setUser(user: User): void;
  setNotification(notification?: NotificationMessage): void;
  logout(): void;
}

const initialState: GlobalState = {
  user: {},
} as GlobalState;

export const GlobalContext = createContext<GlobalState>(initialState);
