import { useState } from 'react';

import { User } from '../models/user';
import { GlobalContext, NotificationMessage } from './GlobalContext';

interface ContextProviderProps {
  children: React.ReactNode;
}

const ContextProvider = ({ children }: ContextProviderProps) => {
  const [user, setUser] = useState<User>({} as User);
  const [notification, setNotification] = useState<NotificationMessage>();

  const logout = () => {
    setUser({} as User);
  };

  return (
    <GlobalContext.Provider
      value={{
        user,
        setUser,
        notification,
        setNotification,
        logout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default ContextProvider;
