import { useState, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import useUser from '../state/useUser';
import paths from '../routing/paths';
import useNotification from '../state/useNotification';
import api from '../api';
import AppButton from '../components/AppButton';
import CardScreen from '../components/CardScreen';
import Input from '../components/Input';

const Login = () => {
  const [user, setUser] = useUser();
  const [, setNotification] = useNotification();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const returnUrl = queryParams.get('returnUrl');

  useEffect(() => {
    if (user.id) {
      navigate(returnUrl || paths.home);
    }
  }, [user, navigate]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setSubmitted(true);

    if (!password || !email) {
      return;
    }

    setIsSaving(true);
    const response = await api.auth.login({
      email: email.trim().toLowerCase(),
      password,
    });

    if (!response.success) {
      setNotification({ message: 'Incorrect credentials' });
      setIsSaving(false);
      return;
    }

    const [userResponse] = await Promise.all([api.user.getMe()]);
    setUser(userResponse);
  };

  return (
    <CardScreen title="Login" showLogo>
      <Box
        component="form"
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '100%',
          mt: 3,
        }}
        onSubmit={handleSubmit}
      >
        <Input
          label="Email"
          name="email"
          value={email}
          onChange={setEmail}
          error="required"
          isError={submitted && !email}
        />
        <Input
          label="Password"
          name="password"
          value={password}
          onChange={setPassword}
          error="required"
          isError={submitted && !password}
          type="password"
        />
        <Box sx={{ mb: 2, textAlign: 'left', width: '100%', marginLeft: 2, marginTop: -1 }}>
          <Link to={paths.passwordForgot} style={{ color: 'inherit' }}>
            <Typography variant="body2">Forgot password?</Typography>
          </Link>
        </Box>
        <Box sx={{ mt: 2, width: '100%' }}>
          <AppButton isLoading={isSaving} isFullWidth type="submit">
            Login
          </AppButton>
          <Typography variant="body2" sx={{ my: 3 }}>
            Don't have an account yet?
          </Typography>
          <Link
            style={{ display: 'block', width: '100%' }}
            to={`${paths.signup}${location.search}`}
          >
            <AppButton color="secondary" type="button">
              Create an account
            </AppButton>
          </Link>
        </Box>
      </Box>
    </CardScreen>
  );
};

export default Login;
