import { useContext } from 'react';

import { GlobalContext, NotificationMessage } from './GlobalContext';

const useNotification: () => [
  NotificationMessage | undefined,
  (message: NotificationMessage | undefined) => void,
] = () => {
  const { notification, setNotification } = useContext(GlobalContext);
  return [notification, setNotification];
};

export default useNotification;
